import React, { useEffect, useMemo, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import actions from "../../../../../redux/actions";
import api from "../../../../../redux/api";
import { connect } from "react-redux";
import { getInputClassName, getProductUrl, numberFormat, toastMessage } from "../../../../helpers";
import _ from 'lodash';
import { Form, Formik, useFormikContext } from "formik";
import schemas from "../../../../schemas";
import SVG from "react-inlinesvg";
import moment from "moment";
import { toAbsoluteUrl, nl2br } from "../../../../../_metronic/_helpers";
import { Dropdown, Modal } from 'react-bootstrap';
import { Button, Switch, Tooltip } from "@material-ui/core";
import StarRatings from "react-star-ratings";
import AwsS3Dropzone from "../../../generic/AwsS3Dropzone";
import ConfirmModal from "../../../modals/ConfirmModal";
import UpgradeLink from "../../../generic/UpgradeLink";
import ProductNavigation from "../../../../layout/navigation/ProductNavigation";
import Editor from "../../../../layout/components/Editor";
import CopyButton from "../../../../layout/components/CopyButton";
import { Link, useHistory } from "react-router-dom";
import UnsavedFormGuard from "../../../../layout/components/UnsavedFormGuard";
import { NeedsUpgradeEvents } from "../../../modals/NeedsUpgrade";
import TrackingPixelModal from "../../../account/UserProfile/components/TrackingPixelModal";
import TrackingGAModal from "../../../account/UserProfile/components/TrackingGAModal";
import AIField from "../../../../layout/ai/AIField";
import { languagesEn } from "../../../../../locales";
import CategoryDropdown from "../../../../layout/components/CategoryDropdown";
import { EditCategoryModal } from "../../Categories";
import { UpgradeEvents } from "../../../modals/UpgradeModal";
import TagDropdown from "../../../../layout/components/TagDropdown";
import { EditTagModal } from "../../../contacts/Tags";
import "./salesPageSettings.scss";

function SalesPageSettings({ match, history, productObj, dispatch, setProduct, currentUser }) {
  let [loading, setLoading] = useState(0),
    [isLive, setIsLive] = useState(false),
    [displayExamplesPopup, setDisplayExamplesPopup] = useState(false),
    [displayReviewPopup, setDisplayReviewPopup] = useState(false),
    [editReviewObj, setEditReviewObj] = useState(null),
    [editReviewIndex, setEditReviewIndex] = useState(0),
    [confirmDeleteReview, setConfirmDeleteReview] = useState(null),
    [uploadError, setUploadError] = useState(null),
    [displayFaqPopup, setDisplayFaqPopup] = useState(false),
    [editFaqObj, setEditFaqObj] = useState(null),
    [editFaqIndex, setEditFaqIndex] = useState(0),
    [confirmDeleteFaq, setConfirmDeleteFaq] = useState(null),
    [exampleNumber, setExampleNumber] = useState(0),
    [aboutFormik, setAboutFormik] = useState(null),
    [expandedReviews, setExpandedReviews] = useState([]),
    [expandedFaq, setExpandedFaq] = useState([]),
    [copyUriTooltip, setCopyUriTooltip] = useState(false),
    [showTrackingPixelModal, setShowTrackingPixelModal] = useState(false),
    [showTrackingGAModal, setShowTrackingGAModal] = useState(false),
    [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false),
    [productLive, setProductLive] = useState(false),
    [salesPageLive, setSalesPageLive] = useState(false),
    [unlistedIsLoading, setUnlistedIsLoading] = useState(),
    [draftModeIsLoading, setDraftModeIsLoading] = useState(),
    [isFree, setIsFree] = useState(),
    formikRefs = {
      domain: useRef(),
      about: useRef(),
      editReview: useRef(),
      editFaq: useRef(),
      language: useRef(),
      category: useRef()
    };

  useEffect(() => {
    if(!productObj || !productObj.episodes || !currentUser)
      return;

    let live = !productObj.draft && !productObj.pendingOwnership && !productObj.overLimit && !productObj.systemDisabled,
      free = productObj.prices.length > 0 && !productObj.prices.find(p => p.type != "free");
    setProductLive(live);
    setIsFree(free);
    setSalesPageLive(live && productObj.salesPageLive && currentUser.planFeatures.salesPage && (free || currentUser.stripeConnected));
  }, [productObj, currentUser]);

  useEffect(() => {
    api.product.getProduct(match.params.id).then(res => {
      dispatch(setProduct(res));
      setIsLive(res.salesPageLive);
    });
  }, []);

  let getInputClasses = (formik, fieldname) => {
    if(formik.touched[fieldname] && formik.errors[fieldname]) return "is-invalid";
    if(formik.touched[fieldname] && !formik.errors[fieldname]) return "is-valid";
    return "";
  };

  let displayFeedback = (formik, fieldName) => formik.touched[fieldName] && formik.errors[fieldName] ? (
    <div className="invalid-feedback-copy">{formik.errors[fieldName]}</div>
  ) : null;

  let updateProduct = () => {
    api.product.getProduct(productObj._id).then(res => {
      dispatch(setProduct(res));
    });
  };

  let sendForm = async (buttonNumber, action, data, successCallback, update = true) => {
    setLoading(buttonNumber);

    let res = await api.product.editSalesPage(productObj._id, {
      action: action,
      product: data
    });

    setLoading(0);

    let ok = res.status != 403 && res.status != 500;

    if(ok && successCallback)
      successCallback();

    //dispatch(setProduct(res.storedObject));

    if(update)
      updateProduct();

    return ok;
  };

  //let onLiveSwitchChanged = ev => {
  //  if(ev.target.checked && !currentUser.planFeatures.salesPage)
  //    return checkLimits.upgradeOrActivate(currentUser);
  //
  //  setIsLive(ev.target.checked);
  //  sendForm(0, "publishSalesPage", { salesPageLive: ev.target.checked }, () => {
  //    toastMessage.success("Your sales page has been updated!");
  //  });
  //};

  let sendAboutForm = data => {
    sendForm(1, "updateAbout", data, () => {
      toastMessage.success("Your sales page has been updated!");
    });
  };

  let sendPriceForm = data => {
    sendForm(2, "updatePrice", data, () => {
      toastMessage.success("Your show has been updated!");
    });
  };

  //let sendUpgradePriceForm = data => {
  //  sendForm(6, "updateUpgradePrice", data, () => {
  //    toastMessage.success("Your show page has been updated!");
  //  });
  //};

  let sendChangeUriForm = data => {
    sendForm(3, "changeUri", data, () => {
      toastMessage.success("Your sales page’s public URL has been updated!");
    });
  };

  let sendTrackingPixelForm = async ({ value }) => {
    if(await sendForm(7, "setTrackingPixel", { facebookPixelId: value })) {
      setShowTrackingPixelModal(false);
      toastMessage.success("Your sales page has been updated!");
    }
  };

  let sendTrackingGAForm = async ({ value }) => {
    if(await sendForm(8, "setTrackingGA", { analyticsId: value })) {
      setShowTrackingGAModal(false);
      toastMessage.success("Your sales page has been updated!");
    }
  };

  let sendReviewForm = data => {
    sendForm(4, "updateReviews", {
      ...data,
      index: editReviewObj ? editReviewIndex : null
    }, () => {
      toastMessage.success("Your reviews have been updated!");
      setDisplayReviewPopup(false);
    });
  };

  let sendFaqForm = data => {
    sendForm(5, "updateFaq", {
      ...data,
      index: editFaqObj ? editFaqIndex : null
    }, () => {
      toastMessage.success("Your FAQ’s have been updated!");
      setDisplayFaqPopup(false);
    });
  };

  let removeReview = index => {
    setConfirmDeleteReview(null);
    sendForm(0, "removeReview", {
      index
    }, () => {
      toastMessage.success("Your reviews have been updated!");
      setDisplayFaqPopup(false);
    });
  };

  let removeFaq = index => {
    setConfirmDeleteFaq(null);
    sendForm(0, "removeFaq", {
      index
    }, () => {
      toastMessage.success("Your FAQ’s have been updated!");
      setDisplayFaqPopup(false);
    });
  };

  function sendLanguageForm(data) {
    sendForm(9, "changeLanguage", data, () => {
      toastMessage.success("Your sales page has been updated!");
    });
  }

  function sendCategoryForm(data) {
    sendForm(10, "updateCategory", data, () => {
      toastMessage.success("Your sales page has been updated!");
    });
  }

  let saveAll = async () => {
    if(formikRefs.domain.current.dirty)
      await sendForm("modal", "changeUri", formikRefs.domain.current.values, null, false);

    if(formikRefs.about.current.dirty)
      await sendForm("modal", "updateAbout", formikRefs.about.current.values, null, false);

    if(formikRefs.editReview.current && formikRefs.editReview.current.dirty)
      await sendForm("modal", "updateReviews", {
        ...formikRefs.editReview.current.values,
        index: editReviewObj ? editReviewIndex : null
      }, null, false);

    if(formikRefs.editFaq.current && formikRefs.editFaq.current.dirty)
      await sendForm("modal", "updateFaq", {
        ...formikRefs.editFaq.current.values,
        index: editFaqObj ? editFaqIndex : null
      }, null, false);

    if(formikRefs.language.current.dirty)
      await sendForm("modal", "changeLanguage", formikRefs.language.current.values, null, false);

    if(formikRefs.category.current.dirty)
      await sendForm("modal", "updateCategory", formikRefs.category.current.values, null, false);

    updateProduct();

    toastMessage.success("Your show page has been updated!");
  };

  async function draftModeOnClick() {
    setDraftModeIsLoading(true);

    await api.product.editProduct({
      product: {
        salesPageLive: 0,
        _id: productObj._id
      }
    });

    await updateProduct();

    toastMessage.success("Show updated!");

    setDraftModeIsLoading(false);
  }

  async function liveModeOnClick() {
    if(!productLive)
      return toastMessage.error("You can’t enable the sales page while the show is not live.");

    if(!currentUser.planFeatures.salesPage)
      return toastMessage.error("Upgrade to enable sales pages.");

    if(!Array.isArray(productObj.prices) || !productObj.prices.length)
      return toastMessage.error("Complete pricing to publish the show.");

    if(!currentUser.stripeConnected && productObj.prices.find(p => p.type != "free"))
      return toastMessage.error("Connect Stripe to publish the show.");

    setDraftModeIsLoading(true);

    await api.product.editProduct({
      product: {
        salesPageLive: 1,
        _id: productObj._id
      }
    });

    await updateProduct();

    toastMessage.success("Show updated!");

    setDraftModeIsLoading(false);
  }

  async function unlistedOnClick(val) {
    setUnlistedIsLoading(true);

    await api.product.editProduct({
      product: {
        unlisted: val,
        _id: productObj._id
      }
    });

    await updateProduct();

    toastMessage.success("Show updated!");

    setUnlistedIsLoading(false);
  }

  let channelName = productObj.name,
    aboutExamples = [
      `<p>Introducing ${channelName}! Complete with one tap install, listen on the go, like a best friend teaching you as you go about 
      your day. Never miss out on another important training or worry about falling behind. With this life-changing ${channelName}, you 
      can learn without sacrificing productivity. Buy today and never lose your focus ever again. </p>
      <p>What you’ll get:</p>        
      <ul><li>Result 1 (without having to do the thing client doesn’t want to do)</li>
      <li>Result 2 (without having to do the thing client doesn’t want to do)</li>
      <li>Result 3 (without having to do the thing client doesn’t want to do)</li></ul>
      <p>Bonus:</p>        
      <ul><li>Fear & doubt buster 1</li>
      <li>Fear & doubt buster 2</li></ul>`,
      `You know what’s better than a best friend? Having one with you wherever you go, going on your morning jog or running errands. 
      It seamlessly coaches you on the go, without having to break your routine! Put your headset on. Open ${channelName} and tap to play 
      a new episode. Listen as you go about your day – while driving, at work, or going for a walk – with someone talking to you like 
      a best friend teaching you the ropes of life. Buy now and get ${channelName} for life!</p>
      <p>What you’ll get:</p>        
      <ul><li>Result 1 (without having to do the thing client doesn’t want to do)</li>
      <li>Result 2 (without having to do the thing client doesn’t want to do)</li>
      <li>Result 3 (without having to do the thing client doesn’t want to do)</li></ul>
      <p>Bonus:</p>        
      <ul><li>Fear & doubt buster 1</li>
      <li>Fear & doubt buster 2</li></ul>`,
      `You love that smart friend who’s always willing to chat, but would never bother you with unimportant things. That’s exactly what 
      ${channelName} does for you! It teaches you cool new tactics just when you need it most. One-tap installation makes this the perfect 
      go-to for life on the run, whether commuting or out taking care of business in 90 degree heat. Listen while driving, working out, or 
      going for a walk.</p>        
      <p>What you’ll get:</p>        
      <ul><li>Result 1 (without having to do the thing client doesn’t want to do)</li>
      <li>Result 2 (without having to do the thing client doesn’t want to do)</li>
      <li>Result 3 (without having to do the thing client doesn’t want to do)</li></ul>
      <p>Bonus:</p>        
      <ul><li>Fear & doubt buster 1</li>
      <li>Fear & doubt buster 2</li></ul>`
    ];

  if(!currentUser || !productObj)
    return <></>;

  return <>
    <h1>{productObj.name}</h1>

    <div className="container-inner sales-page-settings">

      <div className="text-right mb-10">
        {(productObj.host || productObj.publicUri) && (
          <a href={productLive && salesPageLive
            ? getProductUrl(productObj)
            : process.env.REACT_APP_SERVER_URL + "api/sales-page-preview/" + productObj._id} className="btn btn-primary" target="_blank">
            {productLive && salesPageLive ? "View Sales Page" : "Preview Sales Page"}
          </a>
        )}
      </div>

      {!currentUser.stripeConnected && !isFree
        ? (
          <div className="alert mb-10 alert-warning d-flex align-items-center m-0">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} className="svg-icon" />
            <div>
              <h4>Your Stripe account is not connected.</h4>
              You must connect your Stripe account, or set the price to Free, before we can activate your page. <a href="/user/integrations">Connect Stripe Account</a>
            </div>
          </div>
        )
        : <></>}

      <div className={"d-flex gap mb-8 options-row " + (draftModeIsLoading ? "loading spinner" : "")}>
        <button type="button" class={"dashed-option grayscale-icon " + (!salesPageLive ? "active" : "")} onClick={() => draftModeOnClick()}>
          <SVG src="/media/def-image/icons/sales-page-color.svg" />
          <div>
            <label>Draft mode</label>
            <p>Only you can view the sales page</p>
          </div>
        </button>

        <button type="button" class={"dashed-option grayscale-icon " + (salesPageLive ? "active" : "")} onClick={() => liveModeOnClick()}>
          <SVG src="/media/def-image/icons/sales-page-live.svg" />
          <div>
            <label>Live Mode</label>
            <p>Anyone can view the sales page</p>
          </div>
        </button>
      </div>

      {salesPageLive && (
        <div className={"d-flex gap mb-8 options-row " + (unlistedIsLoading ? "loading spinner" : "")}>
          <button type="button" class={"dashed-option " + (productObj.unlisted ? "active" : "")} onClick={() => unlistedOnClick(true)}>
            <span className="radio-icon" />
            <div>
              <label>Hide on the profile page</label>
            </div>
          </button>

          <button type="button" class={"dashed-option " + (!productObj.unlisted ? "active" : "")} onClick={() => unlistedOnClick(false)}>
            <span className="radio-icon" />
            <div>
              <label>Show on the profile page</label>
            </div>
          </button>
        </div>
      )}

      <Pricing product={productObj} user={currentUser} updateProduct={updateProduct} />

      <Coupons product={productObj} user={currentUser} updateProduct={updateProduct} />

      <ThankYou product={productObj} user={currentUser} updateProduct={updateProduct} />

      {/*<Formik
        initialValues={{
          unlockDrip: productObj.upgrade ? productObj.upgrade.unlockDrip : false,
          accessForever: productObj.upgrade ? productObj.upgrade.accessForever : false,
          currency: productObj.upgrade ? productObj.upgrade.currency : "ussd",
          price: productObj.upgrade ? productObj.upgrade.price : ""
        }}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        validationSchema={schemas.product.salesPage.ugprade}
        onSubmit={sendUpgradePriceForm}
      >
        {formik => <>
          <div className="card mb-10">
            <div className="card-header">
              <div>
                <h3 className="card-title fw-bold m-0">Upgrade Price</h3>
                <p className="text-muted">Set a price to unlock instant access and keep the show forever.</p>
              </div>
              <div className="buttons">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="button" className={"btn btn-primary-light " + (loading == 6 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>Set Price</button>
              </div>
            </div>
            <div className="card-body py-9">
              <div className="form-group">
                <label className="checkbox checkbox-lg checkbox-single">
                  <input type="checkbox" onChange={ev => formik.setFieldValue("unlockDrip", ev.target.checked)} value={1} checked={formik.values.unlockDrip} />
                  <span className="mr-4"/>
                  Unlock drip and allow access to all episodes immediately
                </label>
              </div>

              <div className="form-group">
                <label className="checkbox checkbox-lg checkbox-single">
                  <input type="checkbox" onChange={ev => formik.setFieldValue("accessForever", ev.target.checked)} value={1} checked={formik.values.accessForever} />
                  <span className="mr-4"/>
                  Allow access forever
                </label>
              </div>

              <div className="form-group">
                <label>One time price <span className="fw-normal text-muted">This will be added to the first payment</span></label>
                <div className="d-flex">
                  <div className="flex-auto mr-3">
                    <select className="custom-select" {...formik.getFieldProps("currency")}>
                      <option value="usd" key="usd">USD</option>
                      <option value="eur" key="eur">EUR</option>
                      <option value="gbp" key="gbp">GBP</option>
                    </select>
                  </div>
                  <div className="flex-grow-1">
                    <input type="number" step=".01" placeholder="Price" className={"form-control hide-spinner form-control-mw-170 " + getInputClasses(formik, "price")} {...formik.getFieldProps("price")} />
                  </div>
                </div>
                {displayFeedback(formik, "price")}
              </div>

              {(formik.values.period == "weekly" || formik.values.period == "monthly" || formik.values.period == "annual") && <div className="form-group">
                <label>Billing period</label>
                <div>
                  <select className="custom-select w-auto" {...formik.getFieldProps("period")}>
                    <option value="weekly" key="weekly">Weekly</option>
                    <option value="monthly" key="monthly">Monthly</option>
                    <option value="annual" key="annual">Annual</option>
                  </select>
                </div>
              </div>}
            </div>
          </div>
        </>}
      </Formik>*/}

      <Formik
        initialValues={{
          customDomain: productObj.customDomain,
          domainId: productObj.domainId || (currentUser.domains[0] && currentUser.domains[0]._id),
          uri: productObj.publicUri
        }}
        innerRef={formikRefs.domain}
        validationSchema={schemas.product.salesPage.domain}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        onSubmit={sendChangeUriForm}
      >
        {formik => <>
          <div className="card card-profile-header mb-10">
            <div className="card-header">
              <div>
                <h3 className="card-title fw-bold m-0">Domain</h3>
                <p className="text-muted">Edit the domain address to your sales page.</p>
              </div>
              <div>
                <Switch className="blue-switch mr-1" checked={!!formik.values.customDomain} onChange={ev => {
                  if(currentUser.noPlan || !currentUser.planFeatures.domains)
                    //NeedsUpgradeEvents.dispatchShow("Upgrade to add your own domain");
                    UpgradeEvents.dispatchShow();
                  else
                    formik.setFieldValue("customDomain", ev.target.checked);
                }} />
                Use my own domain
              </div>
            </div>
            <div className="card-body pt-9 pb-10">
              {!formik.values.customDomain
                ? (
                  <>
                    <div className="row align-items-center">
                      <div className="col col-auto pr-0">hiro.fm/channel/</div>
                      <div className="col col-auto flex-grow-1 px-2">
                        <input type="text" className={"form-control " + getInputClasses(formik, "uri")} {...formik.getFieldProps("uri")} autoComplete="off" />
                      </div>
                      <div className="col col-auto">
                        <CopyButton successTooltipText="URL copied!" className="mr-4" text={getProductUrl(productObj, true)} />
                        <button type="button" className={"btn btn-primary " + (loading == 3 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>Update</button>
                      </div>
                    </div>
                    {displayFeedback(formik, "uri")}
                  </>
                )
                : (
                  <>
                    {!currentUser.domains.length
                      ? (
                        <div className="text-center">
                          <p className="text-muted">You don‘t have custom domains.</p>
                          <Link to="/user/domains/create" className="btn btn-primary btn-sm">Manage Domains</Link>
                        </div>
                      )
                      : (
                        <>
                          <div className="row align-items-center">
                            <div className="col col-auto">Custom Domain</div>
                            <div className="col col-auto px-2">
                              <select className="custom-select w-auto mw-300" {...formik.getFieldProps("domainId")}>
                                <option></option>
                                {currentUser.domains.map((domain, i) => <option key={i} value={domain._id}>{domain.host}</option>)}
                              </select>
                            </div>
                            <div className="col col-auto flex-grow-1 px-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text pr-0">/</span>
                                </div>
                                <input type="text" className={"form-control pl-0 " + getInputClasses(formik, "uri")} {...formik.getFieldProps("uri")} autoComplete="off" />
                              </div>
                            </div>
                            <div className="col col-auto">
                              <CopyButton successTooltipText="URL copied!" className="mr-4" text={"https://" + currentUser.domains.find(d => d._id == formik.values.domainId)?.host + "/" + formik.values.uri} />
                              <button type="button" className={"btn btn-primary " + (loading == 3 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>Update</button>
                            </div>
                          </div>
                          {displayFeedback(formik, "uri")}
                        </>
                      )}
                  </>
                )}
            </div>
          </div>
        </>}
      </Formik>

      <Formik
        initialValues={{
          categoryId: productObj.categoryId,
          categoryFilter: productObj.categoryFilter
        }}
        innerRef={formikRefs.category}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        onSubmit={sendCategoryForm}
      >
        {formik => <>
          <div className="card card-profile-header mb-10">
            <div className="card-header">
              <div>
                <h3 className="card-title fw-bold m-0">Category</h3>
                <p className="text-muted">Group shows by category and allow only a single category of shows to display on the sales page</p>
              </div>
              <div className="buttons">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="button" className={"btn btn-primary " + (loading == 10 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>Save</button>
              </div>
            </div>
            <div className="card-body pt-9 pb-10">
              <div className="row">
                <div className="col-lg-6 mb-6 mb-lg-0">
                  <div className="form-group">
                    <label>Category</label>
                    <CategoryDropdown onCreateCategoryRequest={() => setShowCreateCategoryModal(true)} value={formik.values.categoryId} onChange={value => formik.setFieldValue("categoryId", value.length ? value[0] : null)} />
                  </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block" />
                <div className="col-lg-5">
                  <div className="form-group">
                    <label>
                      Category Filter
                      <Tooltip title="If enabled, only shows from the same category will be displayed at the bottom of the sales page. If disabled, all shows will be displayed." placement="top">
                        <Button className="p-0 w-auto min-w-auto ml-2"><i className="fa fa-info-circle" aria-hidden="true" /></Button>
                      </Tooltip>
                    </label>
                    <label className="mb-3 d-flex align-items-center">
                      <Switch className="blue-switch mr-1" checked={!!formik.values.categoryFilter} onChange={ev => formik.setFieldValue("categoryFilter", ev.target.checked)} />
                      <span className="pl-2 flex-grow-1">
                        Show only this category on Sales Page
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
      </Formik>
      <EditCategoryModal autoReload onHide={() => setShowCreateCategoryModal(false)} show={showCreateCategoryModal} />

      <Formik
        initialValues={{
          salesPageLanguage: productObj.salesPageLanguage
        }}
        enableReinitialize
        onSubmit={sendLanguageForm}
        innerRef={formikRefs.language}
      >
        {formik => (
          <Form>
            <div className="card mb-10">
              <div className="card-header">
                <h3 className="card-title fw-bold m-0">Language</h3>
              </div>
              <div className="card-body py-9">
                <div className="d-flex">
                  <select className="custom-select mr-3 w-lg-375px" {...formik.getFieldProps("salesPageLanguage")}>
                    <option value="">Use default</option>
                    {Object.keys(languagesEn).map(key => <option key={key} value={key}>{languagesEn[key]}</option>)}
                  </select>
                  <button type="submit" className={"btn btn-primary " + (loading == 9 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="card card-profile-header mb-10">
        <div className="card-header d-lg-flex align-items-center">
          <div className="flex-1">
            <h3 className="card-title fw-bold m-0">Tracking</h3>
            <p className="text-muted">Add a unique Facebook Pixel and Google Analytics code.</p>
          </div>
          {!currentUser.planFeatures.analytics && <UpgradeLink user={currentUser} />}
        </div>
        <div className="card-body pt-9 pb-10">
          <div className="tracking-item">
            <div className="row align-items-center">
              <div className="col-auto">
                <SVG src="/media/def-image/facebook-blue.svg" className="logo" />
              </div>
              <div className="col-auto flex-grow-1">
                <strong>Facebook Pixel</strong>
                {productObj.facebookPixelId == "global" && <span className="text-muted d-inline-block pl-3">(Using global Pixel)</span>}
                {productObj.facebookPixelId == "disabled" && <span className="text-muted d-inline-block pl-3">(Disabled in Profile)</span>}
              </div>
              <div className="col-auto">
                <button disabled={!currentUser.planFeatures.analytics} type="button" className="btn btn-transparent mr-4" onClick={() => setShowTrackingPixelModal(true)}>
                  <SVG src="/media/def-image/icons/pencil.svg" className="svg-icon" />
                </button>
                <SVG src="/media/def-image/icons/check-circle.svg" className={"check-icon " + (!productObj.facebookPixelId || (productObj.facebookPixelId == "global" && !currentUser.facebookPixelId) ? "fill-gray" : "")} />
              </div>
            </div>
          </div>

          <div className="tracking-item">
            <div className="row align-items-center">
              <div className="col-auto">
                <SVG src="/media/def-image/google.svg" className="logo" />
              </div>
              <div className="col-auto flex-grow-1">
                <strong>Google Analytics</strong>
                {productObj.analyticsId == "global" && <span className="text-muted d-inline-block pl-3">(Using global ID)</span>}
                {productObj.analyticsId == "disabled" && <span className="text-muted d-inline-block pl-3">(Disabled in Profile)</span>}
              </div>
              <div className="col-auto">
                <button disabled={!currentUser.planFeatures.analytics} type="button" className="btn btn-transparent mr-4" onClick={() => setShowTrackingGAModal(true)}>
                  <SVG src="/media/def-image/icons/pencil.svg" className="svg-icon" />
                </button>
                <SVG src="/media/def-image/icons/check-circle.svg" className={"check-icon " + (!productObj.analyticsId || (productObj.analyticsId == "global" && !currentUser.analyticsId) ? "fill-gray" : "")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrackingPixelModal where="Sales page" show={showTrackingPixelModal} onHide={() => setShowTrackingPixelModal(false)} user={currentUser} onSubmit={sendTrackingPixelForm} saving={loading == 7} value={productObj.facebookPixelId} useGlobal />
      <TrackingGAModal where="Sales page" show={showTrackingGAModal} onHide={() => setShowTrackingGAModal(false)} user={currentUser} onSubmit={sendTrackingGAForm} saving={loading == 8} value={productObj.analyticsId} useGlobal />

      <Formik
        initialValues={{
          about: productObj.salesPage ? productObj.salesPage.about : ""
        }}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        validationSchema={schemas.product.salesPage.about}
        onSubmit={sendAboutForm}
        innerRef={formikRefs.about}
      >
        {formik => <>
          <div className="card mb-10">
            <div className="card-header">
              <div>
                <h3 className="card-title fw-bold m-0">About your show</h3>
                <p className="text-muted">Describe what your listeners will get from this private audio product. <a href="#" onClick={(ev) => {
                  ev.preventDefault();
                  setAboutFormik(formik);
                  setExampleNumber(0);
                  setDisplayExamplesPopup(true);
                }}>See examples</a></p>
              </div>
              <div className="buttons">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="button" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>Save Changes</button>
              </div>
            </div>
            <div className="card-body py-9">
              <AIField editor formik={formik} name="about" source="sales-page" data={{ productId: productObj._id }} prompt={formik.values.about
                ? "Rewrite this text about my podcas:\n\n" + formik.values.about
                : "Write a short text about this podcast"}>
                <Editor name="about" formik={formik} className="fw" height={184} upgradeToAddMedia={currentUser.planFeatures.fullSalesPage === false} video code imageUpload formatSelect />
              </AIField>
            </div>
          </div>
        </>}
      </Formik>

      <div className={"card mb-10 " + (productObj.salesPage && productObj.salesPage.reviews && productObj.salesPage.reviews.length ? "" : "card-empty")}>
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">Reviews</h3>
            <p className="text-muted">Add reviews to your sales page.  If you don’t have any yet, that’s fine. You can always add more later.</p>
          </div>
          <div className="buttons">
            {currentUser.planFeatures.fullSalesPage !== false
              ? <button type="button" className="btn btn-primary-light" onClick={() => {
                setEditReviewObj(null);
                setDisplayReviewPopup(true);
              }}>
                <SVG src="/media/def-image/icons/plus-square.svg" className="svg-icon" />
                Add review
              </button>
              : <UpgradeLink user={currentUser} />}
          </div>
        </div>
        {productObj.salesPage && Array.isArray(productObj.salesPage.reviews) && currentUser.planFeatures.fullSalesPage !== false && (
          <div className="card-body py-9 reviews-list">
            {(() => {
              let list = [];
              for(let i = 0; i < productObj.salesPage.reviews.length; i++) {
                let item = productObj.salesPage.reviews[i];

                list.push(<>
                  <div className={"review-item " + (expandedReviews.includes(i) ? "expanded" : "")}>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <button type="button" className="btn-expand" onClick={() => {
                          if(expandedReviews.includes(i))
                            setExpandedReviews(expandedReviews.filter(x => x != i));
                          else
                            setExpandedReviews(expandedReviews.concat(i));
                        }} />
                      </div>
                      <div className="col-auto flex-grow-1">
                        <div className="d-flex align-items-center">
                          <img src={item.pictureUrl || toAbsoluteUrl("/media/users/blank.png")} className="review-cover" />
                          <div className="flex-grow-1 review-name">
                            <strong className="d-block mb-1">{item.firstName + " " + item.lastName}</strong>
                            <div className="review-stars">
                              {(() => {
                                let stars = [];
                                for(let i = 1; i <= 5; i++)
                                  stars.push(<SVG key={i} src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} className={"svg-icon " + (item.rating >= i ? "star-yellow" : "star-gray")} />);
                                return stars;
                              })()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <button type="button" className="btn btn-transparent mr-4" onClick={() => {
                          setEditReviewObj(item);
                          setEditReviewIndex(i);
                          setDisplayReviewPopup(true);
                        }}><SVG src="/media/def-image/icons/pencil.svg" className="svg-icon" /></button>
                        <button type="button" className="btn btn-transparent" onClick={() => setConfirmDeleteReview(i)}><SVG src="/media/def-image/icons/delete.svg" className="svg-icon" /></button>
                      </div>
                    </div>
                    <div className="expanded-text">{nl2br(item.review)}</div>
                  </div>
                </>);
              }
              return list;
            })()}
          </div>
        )}
      </div>

      <div className={"card mb-10 " + (productObj.salesPage && productObj.salesPage.faq && productObj.salesPage.faq.length ? "" : "card-empty")}>
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">FAQ’s</h3>
            <p className="text-muted">Edit or delete the default FAQ’s and add your own. </p>
          </div>
          <div className="buttons">
            {currentUser.planFeatures.fullSalesPage !== false
              ? <button type="button" className="btn btn-primary-light" onClick={() => {
                setEditFaqObj(null);
                setDisplayFaqPopup(true);
              }}>
                <SVG src="/media/def-image/icons/plus-square.svg" className="svg-icon" />
                Add FAQ
              </button>
              : <UpgradeLink user={currentUser} />}
          </div>
        </div>
        {productObj.salesPage && Array.isArray(productObj.salesPage.faq) && currentUser.planFeatures.fullSalesPage !== false && (
          <div className="card-body py-9">
            {(() => {
              let list = [];
              for(let i = 0; i < productObj.salesPage.faq.length; i++) {
                let item = productObj.salesPage.faq[i];

                list.push(<>
                  <div className={"faq-item " + (expandedFaq.includes(i) ? "expanded" : "")}>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <button type="button" className="btn-expand" onClick={() => {
                          if(expandedFaq.includes(i))
                            setExpandedFaq(expandedFaq.filter(x => x != i));
                          else
                            setExpandedFaq(expandedFaq.concat(i));
                        }} />
                      </div>
                      <div className="col-auto flex-grow-1">
                        <strong>{item.question}</strong>
                      </div>
                      {currentUser.planFeatures.fullSalesPage
                        ? <div className="col-auto">
                          <button type="button" className="btn btn-transparent mr-4" onClick={() => {
                            setEditFaqObj(item);
                            setEditFaqIndex(i);
                            setDisplayFaqPopup(true);
                          }}><SVG src="/media/def-image/icons/pencil.svg" className="svg-icon" /></button>
                          <button type="button" className="btn btn-transparent" onClick={() => setConfirmDeleteFaq(i)}><SVG src="/media/def-image/icons/delete.svg" className="svg-icon" /></button>
                        </div>
                        : <></>}
                    </div>
                    <div className="expanded-text">{nl2br(item.response)}</div>
                  </div>
                </>);
              }
              return list;
            })()}
          </div>
        )}
      </div>

      {/*<div className="card mb-10">
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">Connected accounts</h3>
            <p className="text-muted">Automatically add customers to your email list.</p>
          </div>
          <button type="button" className="btn btn-primary-light">            
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")} className="svg-icon"/>            
            Connect application
          </button>
        </div>
        <div className="card-body py-9">
          <Switch/> 
          Tag contacts on purchase
        </div>
      </div>*/}





    </div>

    <Modal
      size="md"
      show={displayExamplesPopup}
      onHide={() => setDisplayExamplesPopup(false)}
      centered
      className="text-examples-modal"
    >
      <Modal.Header closeButton>
        <h1 className="m-0">Examples</h1>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Example {exampleNumber + 1}:</strong></p>
        <div dangerouslySetInnerHTML={{ __html: aboutExamples[exampleNumber] }}></div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary mr-2" onClick={() => {
          if(exampleNumber == 0)
            setDisplayExamplesPopup(false);
          else
            setExampleNumber(exampleNumber - 1);
        }}>Back</button>
        <button type="button" className="btn btn-secondary mr-2" onClick={() => setExampleNumber(exampleNumber + 1)} disabled={exampleNumber + 1 == aboutExamples.length}>Next</button>
        <button type="button" className="btn btn-primary" onClick={() => {
          aboutFormik.setFieldValue("about", aboutExamples[exampleNumber]);
          setDisplayExamplesPopup(false);
        }}>Use This</button>
      </Modal.Footer>
    </Modal>

    <Modal
      size="lg"
      show={displayReviewPopup}
      onHide={() => setDisplayReviewPopup(false)}
      centered
      className="edit-review-modal"
      enforceFocus={false}
    >
      <Formik
        initialValues={editReviewObj || {
          pictureUrl: "",
          rating: 0,
          firstName: "",
          lastName: "",
          review: ""
        }}
        innerRef={formikRefs.editReview}
        validationSchema={schemas.product.salesPage.review}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        onSubmit={sendReviewForm}
      >
        {formik => <>

          <Modal.Header>
            <h1 className="m-0">{editReviewObj ? "Edit" : "New"} review</h1>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">Photo</label>
              <div className="dropzone-artwork-container dropzone-review-cover">
                <AwsS3Dropzone
                  allowMultipleUpload={false}
                  onError={error => setUploadError(error)}
                  onUploadComplete={files => files.length == 1 && formik.setFieldValue("pictureUrl", files[0].amazonS3Url)}
                  forceSquareImageFormat={true}
                  fileType={'image/jpg, image/jpeg, image/png'}
                  uploadType="artwork">
                  <div className="dropzone-uploaded-image spinner">
                    <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: `url(${toAbsoluteUrl(formik.values.pictureUrl || "/media/users/blank.png")}` }}>
                      <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change" data-toggle="tooltip" title="Change picture"><i className="fa fa-pen icon-sm text-muted" /></span>
                      {formik.values.pictureUrl && <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove" data-toggle="tooltip" title="Remove picture" onClick={ev => {
                          ev.stopPropagation();
                          formik.setFieldValue("pictureUrl", null);
                        }}><i className="ki ki-bold-close icon-xs text-muted" /></span>}
                    </div>
                  </div>
                </AwsS3Dropzone>
              </div>
              {uploadError ? <div className="invalid-feedback-copy">{uploadError}</div> : <div className="invalid-feedback-copy text-gray">Allowed file types: png, jpg, jpeg</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Rating</label>
              <div>
                <StarRatings
                  rating={formik.values.rating}
                  starRatedColor="#ffa800"
                  starHoverColor="#ffa800"
                  starDimension="22px"
                  starSpacing="3px"
                  changeRating={v => {
                    formik.setFieldValue("rating", v);
                  }}
                  numberOfStars={5}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">Full Name <em>*</em></label>
              <div className="row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                  <input type="text" className={"form-control " + getInputClasses(formik, "firstName")} {...formik.getFieldProps("firstName")} />
                  {displayFeedback(formik, "firstName")}
                </div>
                <div className="col-sm-6">
                  <input type="text" className={"form-control " + getInputClasses(formik, "lastName")} {...formik.getFieldProps("lastName")} />
                  {displayFeedback(formik, "lastName")}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">Review <em>*</em></label>
              <AIField formik={formik} name="review" source="sales-page" data={{ productId: productObj._id }} prompt={formik.values.review
                ? "Rewrite this review:\n\n" + formik.values.review
                : "Write a nice short review of this podcast"}>
                <textarea rows="5" className={"form-control " + getInputClasses(formik, "review")} {...formik.getFieldProps("review")} />
              </AIField>
              {displayFeedback(formik, "review")}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setDisplayReviewPopup(false)}>Cancel</button>
            <button type="button" className={"btn btn-primary " + (loading == 4 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>{editReviewObj ? "Save" : "Add"} review</button>
          </Modal.Footer>

        </>}
      </Formik>
    </Modal>

    <Modal
      size="lg"
      show={displayFaqPopup}
      onHide={() => setDisplayFaqPopup(false)}
      centered
      className="edit-faq-modal"
      enforceFocus={false}
    >
      <Formik
        initialValues={editFaqObj || {
          question: "",
          response: ""
        }}
        innerRef={formikRefs.editFaq}
        validationSchema={schemas.product.salesPage.faq}
        validateOnBlur={true}
        validateOnChange={true}
        validateOnMount={false}
        enableReinitialize
        onSubmit={sendFaqForm}
      >
        {formik => <>

          <Modal.Header>
            <h1 className="m-0">{editReviewObj ? "Edit" : "Add"} FAQ</h1>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group">
              <label className="form-label">Question <em>*</em></label>
              <AIField source="sales-page" data={{ productId: productObj._id }} prompt={formik.values.question
                ? "Rewrite this question about my podcast:\n\n" + formik.values.question
                : "Write one FAQ for this podcast"} onResponse={text => {
                  let values = {};

                  const matches = [...text.matchAll(/^(Q|A): (.+)$/mg)];
                  if(matches.length)
                    for(let match of matches) {
                      if(match[1] == "Q")
                        values.question = match[2].trim();
                      if(match[1] == "A")
                        values.response = match[2].trim();
                    }
                  else
                    values.question = text;

                  formik.setValues(values, true);
                }}>
                <input type="text" className={"form-control " + getInputClasses(formik, "question")} {...formik.getFieldProps("question")} />
              </AIField>
              {displayFeedback(formik, "question")}
            </div>

            <div className="form-group">
              <label className="form-label">Response <em>*</em></label>
              <AIField formik={formik} name="response" source="sales-page" data={{ productId: productObj._id }} prompt={formik.values.response
                ? "Rewrite this text:\n\n" + formik.values.response
                : "Write an answer to this question about my podcast:\n\n" + formik.values.question}>
                <textarea rows="5" className={"form-control " + getInputClasses(formik, "response")} {...formik.getFieldProps("response")} />
              </AIField>
              {displayFeedback(formik, "response")}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setDisplayFaqPopup(false)}>Cancel</button>
            <button type="button" className={"btn btn-primary " + (loading == 5 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>{editFaqObj ? "Save" : "Add"} FAQ</button>
          </Modal.Footer>

        </>}
      </Formik>
    </Modal>

    <ConfirmModal
      show={confirmDeleteReview !== null}
      message="Do you want to delete this review?"
      onConfirm={() => removeReview(confirmDeleteReview)}
      onCancel={() => setConfirmDeleteReview(null)} />
    <ConfirmModal
      show={confirmDeleteFaq !== null}
      message="Do you want to delete this question?"
      onConfirm={() => removeFaq(confirmDeleteFaq)}
      onCancel={() => setConfirmDeleteFaq(null)} />

    <UnsavedFormGuard formikRef={Object.values(formikRefs)} onSaveAsync={saveAll} loading={loading == "modal"} />
  </>;
}

function Pricing({ product, user, updateProduct }) {
  let [showModal, setShowModal] = useState(),
    [editItem, setEditItem] = useState(),
    [loading, setLoading] = useState(),
    [deleteItem, setDeleteItem] = useState(),
    [showConfirmDelete, setShowConfirmDelete] = useState(),
    [detailsItem, setDetailsItem] = useState(),
    [showDetailsModal, setShowDetailsModal] = useState();

  function addPriceOnClick() {
    setEditItem(null);
    setShowModal(true);
  }

  function editOnClick(item) {
    setEditItem(item);
    setShowModal(true);
  }

  async function archiveOnClick(item, value) {
    setLoading("item_" + item._id);

    let res = await api.product.updatePrice(product._id, item._id, { isArchived: value });

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Price updated!");

    updateProduct();
  }

  function deleteOnClick(item) {
    setDeleteItem(item);
    setShowConfirmDelete(true);
  }

  async function confirmDelete() {
    setLoading("delete");

    let res = await api.product.deletePrice(product._id, deleteItem._id);

    setLoading(false);
    setShowConfirmDelete(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Price deleted!");

    updateProduct();
  }

  function salesOnClick(ev, item) {
    ev.preventDefault();
    setDetailsItem(item);
    setShowDetailsModal(true);
  }

  let canAddPrices = user.planFeatures.prices < 0 || !Array.isArray(product.prices) || user.planFeatures.prices > product.prices;

  return (
    <>
      <div className="card mb-10">
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">
              Pricing
              {!canAddPrices && <UpgradeLink user={user} className="ml-3" />}
            </h3>
            <p className="text-muted">Set one or more prices for your audio show.</p>
          </div>
          <div className="buttons">
            <button type="button" disabled={!canAddPrices} className="btn btn-primary-light" onClick={() => addPriceOnClick()}>
              <SVG src="/media/def-image/icons/plus-square.svg" className="svg-icon" />
              Add Price
            </button>
          </div>
        </div>

        {Array.isArray(product.prices) && product.prices.length > 0 && (
          <div className="card-body py-9">
            <div className="table-responsive">
              <table className="table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th>Price</th>
                    <th width="1" align="center">Status</th>
                    <th>Name</th>
                    <th>Tags</th>
                    <th>Date created</th>
                    <th>Sales</th>
                    <th width="1" />
                  </tr>
                </thead>
                <tbody>
                  {product.prices.map(price => (
                    <tr key={price._id}>
                      <td>
                        {price.type == "free" && "Free"}

                        {price.type == "pay-what-you-want" && "Pay what you want"}

                        {(price.type == "one-time" || price.type == "subscription") && (
                          <>
                            ${numberFormat(price.amount)}<br />
                            <small>
                              {price.type == "one-time" && (
                                <>
                                  <SVG src="/media/def-image/icons/one-time-sm.svg" className="mr-2" />
                                  One time
                                </>
                              )}

                              {price.type == "subscription" && (
                                <>
                                  <SVG src="/media/def-image/icons/recurring-sm.svg" className="mr-2" />
                                  {price.period == "weekly" && "per week"}
                                  {price.period == "monthly" && "per month"}
                                  {price.period == "annual" && "per year"}
                                </>
                              )}
                            </small>
                          </>
                        )}
                      </td>
                      <td align="center">
                        <span className={"dot " + (price.isArchived ? "bg-red" : "bg-green")} />
                      </td>
                      <td>
                        {price.name}
                      </td>
                      <td>
                        {price.tags.map(tag => (
                          <span className="badge mr-2" key={tag}>{tag}</span>
                        ))}
                      </td>
                      <td>
                        {moment(price.createdAt).locale("en").format("MMM D, YYYY")}
                      </td>
                      <td>
                        {price.salesCount > 0
                          ? (
                            <a href="#" className="fw-bold" onClick={ev => salesOnClick(ev, price)}>{price.salesCount}</a>
                          )
                          : (
                            <span>{price.salesCount}</span>
                          )}
                      </td>
                      <td>
                        <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                          <Dropdown.Toggle className={loading == "item_" + price._id ? " loading spinner spinner-dark" : ""}>
                            <SVG src="/media/def-image/icons/dots-circle.svg" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                            <button type="button" className="dropdown-item" onClick={() => editOnClick(price)}>
                              <div className="icon">
                                <SVG src="/media/def-image/icons/pencil.svg" />
                              </div>
                              Edit price
                            </button>

                            {price.isArchived && (
                              <button type="button" className="dropdown-item" onClick={() => archiveOnClick(price, false)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/refresh.svg" />
                                </div>
                                Restore price
                              </button>
                            )}

                            {!price.isArchived && (
                              <button type="button" className="dropdown-item" onClick={() => archiveOnClick(price, true)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/archive.svg" />
                                </div>
                                Archive price
                              </button>
                            )}

                            <button type="button" className="dropdown-item" onClick={() => deleteOnClick(price)}>
                              <div className="icon">
                                <SVG src="/media/def-image/icons/trash.svg" />
                              </div>
                              Delete price
                            </button>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <EditPriceModal product={product} editItem={editItem} user={user} show={showModal} onHide={() => setShowModal(false)} updateProduct={updateProduct} />

      <PriceDetailsModal product={product} item={detailsItem} show={showDetailsModal} onHide={() => setShowDetailsModal(false)} />

      <ConfirmModal
        show={showConfirmDelete}
        message="Are you sure you want to delete this price?"
        onConfirm={() => confirmDelete()}
        onCancel={() => setShowConfirmDelete(false)}
        loading={loading == "delete"} />
    </>
  );
}

function PriceDetailsModal({ product, item, show, onHide }) {
  let [data, setData] = useState([]),
    [loading, setLoading] = useState(true),
    history = useHistory();

  const price = item;

  async function loadData() {
    setLoading(true);
    setData([]);

    let res = await api.product.getSales({
      priceId: item._id
    });

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setData(res.data);
  }

  function listenerOnClick(ev, id) {
    ev.preventDefault();
    history.push("/contacts/" + id + "/edit");
  }

  useEffect(() => {
    if(item)
      loadData();
  }, [item]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      centered
      className="price-details-modal"
      enforceFocus={false}>
      <Modal.Header>
        <h1 className="m-0">
          {item?.name}
        </h1>
      </Modal.Header>
      <Modal.Body>
        {loading
          ? (
            <div className="loading-block spinner" />
          )
          : (
            <div className="table-responsive">
              <table className="table table-head-custom">
                <thead>
                  <tr>
                    <th className="pl-0">Name</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>
                      {price?.type == "pay-what-you-want"
                        ? "Amount paid"
                        : "Coupon"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!data.length && (
                    <tr>
                      <td colSpan="10" className="text-center">No data available.</td>
                    </tr>
                  )}
                  {data.map((item, i) => (
                    <tr key={i}>
                      <td className="pl-0 text-wrap">
                        <a href="#" className="text-inherit" onClick={ev => listenerOnClick(ev, item.listener.id)}>
                          {item.listener.name}
                        </a>
                      </td>
                      <td>
                        <a href="#" className="text-inherit" onClick={ev => listenerOnClick(ev, item.listener.id)}>
                          {item.listener.email}
                        </a>
                      </td>
                      <td>
                        {moment(item.date).locale("en").format("MMM D, YYYY")}
                      </td>
                      <td>
                        {price?.type == "pay-what-you-want"
                          ? (
                            item.lastAmountPaid
                              ? price?.currency?.toUpperCase() + " " + numberFormat(item.lastAmountPaid)
                              : "-"
                          )
                          : (
                            item.coupon
                              ? <span className="badge gray-badge">{item.coupon.name.toUpperCase()}</span>
                              : <span className="text-muted">(none)</span>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-secondary mr-2" onClick={() => onHide()}>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

function SalesTypeCard({ type, label, description, active, onClick, disabled = false }) {
  return <button type="button" disabled={disabled} className={"sales-type-card " + (active ? "active" : "")} onClick={onClick}>
    <div className="sales-type-card-text-container">
      <div className="sales-type-card-label">{label}</div>
      <div className="sales-type-card-description">{description}</div>
    </div>
    <SVG src="/media/def-image/icons/check-circle-blue.svg" className="sales-type-card-icon" />
  </button>;
}

/* seems like there is no normal reactive way to retrieve formik values, so had to do this: */
function WatchFormikValues({ onChange }) {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values]);

  return null;
}

function EditPriceModal({ product, user, show, onHide, updateProduct, editItem }) {
  let [saving, setSaving] = useState(),
    [showCreateTagModal, setShowCreateTagModal] = useState();

  const [formValues, setFormValues] = useState();

  async function onSubmit(values) {
    if((values.type === 'one-time' || values.type === 'subscription') && values.amount === "") {
      /* even though schema set up not to allow null or 0, for some reason it still accepts empty string
       * so instead protect using this logic with toast message. */
      return toastMessage.error((res && res.error) || "Amount must be greater than 0.");
    }

    setSaving(true);

    /* ensure min amount is always set to correctly display it later. */
    if(values.type === 'pay-what-you-want' && !('minAmount' in values)) {
      values.minAmount = 0;
    }

    let res = editItem
      ? await api.product.updatePrice(product._id, editItem._id, values)
      : await api.product.createPrice(product._id, values);

    setSaving(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success(editItem
      ? "Price updated!"
      : "Price added!");

    updateProduct();
    onHide();
  }

  const schema = useMemo(() => {
    if(formValues && (formValues?.type === "one-time" || formValues?.type === "subscription")) {
      console.log('check');
      return schemas.product.salesPage.price(1);
    }

    return schemas.product.salesPage.price(0);

  }, [formValues?.type]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      centered
      className="edit-price-modal"
      enforceFocus={false}>

      <Formik
        initialValues={editItem || {
          name: "",
          type: "free",
          period: "monthly",
          amount: 0.00,
          currency: "usd",
          tags: []
        }}
        validationSchema={schema}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        onSubmit={onSubmit}>

        {formik => <>
          <WatchFormikValues onChange={setFormValues} />
          <Modal.Header>
            <h1 className="m-0">
              {editItem
                ? "Edit Price"
                : "New Price"}
            </h1>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">Price name</label>
              <input type="text" className={getInputClassName(formik, "name")} {...formik.getFieldProps("name")} />
              {formik.touched.name && formik.errors.name && <div className="field-error">{formik.errors.name}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Sales type</label>
              <div className="sales-types-list">
                <SalesTypeCard disabled={!!editItem} type="free" label="Lead Magnet" description="Allow access for free." active={formik.values.type == "free"} onClick={() => formik.setFieldValue("type", "free")} />
                <SalesTypeCard disabled={!!editItem} type="one-time" label="One-time payment" description="Charge a one-time fee." active={formik.values.type == "one-time"} onClick={() => formik.setFieldValue("type", "one-time")} />
                <SalesTypeCard disabled={!!editItem} type="subscription" label="Subscription" description="Charge an ongoing fee." active={formik.values.type == "subscription"} onClick={() => formik.setFieldValue("type", "subscription")} />
                <SalesTypeCard disabled={!!editItem} type="pay-what-you-want" label="Pay what you want" description="Customers set the price." active={formik.values.type == "pay-what-you-want"} onClick={() => formik.setFieldValue("type", "pay-what-you-want")} />
              </div>
            </div>

            {formik.values.type != "free" && (
              <div className="form-group">
                <div className="d-flex align-items-end">
                  <div className="flex-auto align-items-end mr-3">
                    <label className="form-label mb-0">
                      {formik.values.type == "pay-what-you-want"
                        ? "Currency"
                        : "Price"}
                      <select className="custom-select" disabled={formik.values.type != "pay-what-you-want" && (!!editItem || !formik.values.period || formik.values.period == "free")} {...formik.getFieldProps("currency")}>
                        <option value="usd">USD</option>
                        <option value="eur">EUR</option>
                        <option value="gbp">GBP</option>
                        <option value="aud">AUD</option>
                        <option value="pln">PLN</option>
                        <option value="sek">SEK</option>
                        <option value="chf">CHF</option>
                      </select>
                    </label>
                  </div>

                  {formik.values.type !== "pay-what-you-want" && (
                    <div className="flex-auto mr-3">
                      <input type="number" step=".01" disabled={!!editItem} placeholder="Price" className={getInputClassName(formik, "amount", "hide-spinner form-control-mw-170")} {...formik.getFieldProps("amount")} />
                    </div>
                  )}

                  {formik.values.type == "subscription" && (
                    <div className="flex-auto">
                      <select className="custom-select w-auto" disabled={!!editItem} {...formik.getFieldProps("period")}>
                        <option value="weekly" key="weekly">Weekly</option>
                        <option value="monthly" key="monthly">Monthly</option>
                        <option value="annual" key="annual">Annual</option>
                      </select>
                    </div>
                  )}

                  {formik.values.type == "pay-what-you-want" && (
                    <>
                      <label className="form-label mb-0">
                        Minimum price
                        <div className="flex-auto mr-3">
                          <input type="number" step=".01" className={getInputClassName(formik, "", "hide-spinner form-control-mw-170")} {...formik.getFieldProps("minAmount")} />
                        </div>
                      </label>
                      <label className="form-label mb-0">
                        Suggested price
                        <div className="flex-auto mr-3">
                          <input type="number" step=".01" className={getInputClassName(formik, "", "hide-spinner form-control-mw-170")} {...formik.getFieldProps("suggestedAmount")} />
                        </div>
                      </label>
                    </>
                  )}
                </div>

                {formik.touched.amount && formik.errors.amount && <div className="field-error">{formik.errors.amount}</div>}
              </div>
            )}

            <div className="price-form-divider" />

            <div className="form-group">
              <label className="form-label has-icon">
                <SVG src="/media/def-image/icons/tag-color.svg" className="icon" />
                <span className="label-text">
                  Tag contact {!user.planFeatures.releaseByTags && <UpgradeLink user={user} className="ml-3" />}<br />
                  <small>Tag the contact to give them access to all episodes with the same tag</small>
                </span>
              </label>

              <TagDropdown onCreateTagRequest={() => setShowCreateTagModal(true)} disabled={!user.planFeatures.releaseByTags} product={product} placeholder="Select tags" multiple value={formik.values.tags} onChange={value => formik.setFieldValue("tags", value)} />

              <EditTagModal autoReload onTagAdded={t => formik.setFieldValue("tags", [...(formik.values.tags || []), t])} onHide={() => setShowCreateTagModal(false)} show={showCreateTagModal} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => onHide()}>Cancel</button>
            <button type="button" className={"btn btn-primary " + (saving ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>{editItem ? "Save price" : "Add price"}</button>
          </Modal.Footer>
        </>}
      </Formik>
    </Modal>
  );
}

function ThankYou({ product, user, updateProduct }) {
  let ref = useRef(),
    [saving, setSaving] = useState();

  async function onSubmit(values) {
    setSaving(true);

    let res = await api.product.editSalesPage(product._id, {
      action: "updateThankYou",
      product: values
    });

    setSaving(false);

    if(res.status != 403 && res.status != 500)
      toastMessage.success("Your sales page’s settings have been updated!");

    updateProduct();
  }

  return (
    <Formik
      initialValues={{
        thankYouUrl: product.thankYouUrl || ""
      }}
      innerRef={ref}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={schemas.product.salesPage.thankYou}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {formik => <>
        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <div>
              <h3 className="card-title fw-bold m-0">
                Thank you redirect
                {!user.planFeatures.thankYouRedirect && <UpgradeLink user={user} className="ml-3" />}
              </h3>
              <p className="text-muted">After purchase, redirect users to web address.</p>
            </div>
            <div className="buttons">
              <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
              <button type="button" className={"btn btn-primary " + (saving ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>Save</button>
            </div>
          </div>

          <div className="card-body p-9">
            <div className="form-group">
              <label className="form-label">Thank you URL</label>
              <input type="text" disabled={!user.planFeatures.thankYouRedirect} placeholder="https://mywebsite.com" className={getInputClassName(formik, "thankYouUrl")} {...formik.getFieldProps("thankYouUrl")} />

              {formik.touched.thankYouUrl && formik.errors.thankYouUrl && <div className="field-error">{formik.errors.thankYouUrl}</div>}
            </div>
          </div>
        </div>

        <UnsavedFormGuard formikRef={ref} onSaveAsync={() => formik.submitForm()} loading={saving} />
      </>}
    </Formik>
  );
}

function Coupons({ product, user, updateProduct }) {
  let [showModal, setShowModal] = useState(),
    [editItem, setEditItem] = useState(),
    [loading, setLoading] = useState(),
    [deleteItem, setDeleteItem] = useState(),
    [showConfirmDelete, setShowConfirmDelete] = useState(),
    [detailsItem, setDetailsItem] = useState(),
    [showDetailsModal, setShowDetailsModal] = useState();

  function addCouponOnClick() {
    setEditItem(null);
    setShowModal(true);
  }

  function editOnClick(item) {
    setEditItem(item);
    setShowModal(true);
  }

  async function archiveOnClick(item, value) {
    setLoading("item_" + item._id);

    let res = await api.product.updateCoupon(product._id, item._id, { isArchived: value });

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Coupon updated!");

    updateProduct();
  }

  function deleteOnClick(item) {
    setDeleteItem(item);
    setShowConfirmDelete(true);
  }

  async function confirmDelete() {
    setLoading("delete");

    let res = await api.product.deleteCoupon(product._id, deleteItem._id);

    setLoading(false);
    setShowConfirmDelete(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Coupon deleted!");

    updateProduct();
  }

  function redemptionsOnClick(ev, item) {
    ev.preventDefault();
    setDetailsItem(item);
    setShowDetailsModal(true);
  }

  let canAddCoupons = user.planFeatures.coupons < 0 || !Array.isArray(product.coupons) || user.planFeatures.coupons > product.coupons;

  return (
    <>
      <div className="card mb-10">
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">
              Coupons
              {!canAddCoupons && <UpgradeLink user={user} className="ml-3" />}
            </h3>
            <p className="text-muted">Create coupons for your customers.</p>
          </div>
          <div className="buttons">
            <button type="button" disabled={!canAddCoupons} className="btn btn-primary-light" onClick={() => addCouponOnClick()}>
              <SVG src="/media/def-image/icons/plus-square.svg" className="svg-icon" />
              Add Coupon
            </button>
          </div>
        </div>

        {Array.isArray(product.coupons) && product.coupons.length > 0 && (
          <div className="card-body py-9">
            <div className="table-responsive">
              <table className="table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th>Coupon code</th>
                    <th width="1" align="center">Status</th>
                    <th>Discount</th>
                    <th>Redemptions</th>
                    <th width="1" />
                  </tr>
                </thead>
                <tbody>
                  {product.coupons.map(coupon => (
                    <tr key={coupon._id}>
                      <td>
                        {coupon.name.toUpperCase()}
                      </td>
                      <td align="center">
                        <span className={"dot " + (coupon.isArchived ? "bg-red" : "bg-green")} />
                      </td>
                      <td>
                        {coupon.type == "fixed" && coupon.currency.toUpperCase() + " " + coupon.amount}

                        {coupon.type == "percentage" && coupon.amount + "%"}
                      </td>
                      <td>
                        {coupon.redemptionsCount > 0
                          ? (
                            <a href="#" onClick={ev => redemptionsOnClick(ev, coupon)} className="fw-bold">{coupon.redemptionsCount}</a>
                          )
                          : (
                            <span>{coupon.redemptionsCount}</span>
                          )}
                      </td>
                      <td>
                        <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                          <Dropdown.Toggle className={loading == "item_" + coupon._id ? " loading spinner spinner-dark" : ""}>
                            <SVG src="/media/def-image/icons/dots-circle.svg" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                            <button type="button" className="dropdown-item" onClick={() => editOnClick(coupon)}>
                              <div className="icon">
                                <SVG src="/media/def-image/icons/pencil.svg" />
                              </div>
                              Edit coupon
                            </button>

                            {coupon.isArchived && (
                              <button type="button" className="dropdown-item" onClick={() => archiveOnClick(coupon, false)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/refresh.svg" />
                                </div>
                                Restore coupon
                              </button>
                            )}

                            {!coupon.isArchived && (
                              <button type="button" className="dropdown-item" onClick={() => archiveOnClick(coupon, true)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/archive.svg" />
                                </div>
                                Archive coupon
                              </button>
                            )}

                            <button type="button" className="dropdown-item" onClick={() => deleteOnClick(coupon)}>
                              <div className="icon">
                                <SVG src="/media/def-image/icons/trash.svg" />
                              </div>
                              Delete coupon
                            </button>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <EditCouponModal product={product} editItem={editItem} user={user} show={showModal} onHide={() => setShowModal(false)} updateProduct={updateProduct} />

      <CouponDetailsModal product={product} item={detailsItem} show={showDetailsModal} onHide={() => setShowDetailsModal(false)} />

      <ConfirmModal
        show={showConfirmDelete}
        message="Are you sure you want to delete this coupon?"
        onConfirm={() => confirmDelete()}
        onCancel={() => setShowConfirmDelete(false)}
        loading={loading == "delete"} />
    </>
  );
}

function EditCouponModal({ product, user, show, onHide, updateProduct, editItem }) {
  let [saving, setSaving] = useState();

  async function onSubmit(values) {
    setSaving(true);

    let res = editItem
      ? await api.product.updateCoupon(product._id, editItem._id, values)
      : await api.product.createCoupon(product._id, values);

    setSaving(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success(editItem
      ? "Coupon updated!"
      : "Coupon added!");

    updateProduct();
    onHide();
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      centered
      className="edit-coupon-modal"
      enforceFocus={false}>

      <Formik
        initialValues={editItem || {
          name: "",
          type: "percentage",
          amount: 0,
          currency: "usd"
        }}
        validationSchema={schemas.product.salesPage.coupon}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        onSubmit={onSubmit}>

        {formik => <>
          <Modal.Header>
            <h1 className="m-0">
              {editItem
                ? "Edit Coupon"
                : "New Coupon"}
            </h1>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">Coupon code</label>
              <input type="text" className={getInputClassName(formik, "name")} {...formik.getFieldProps("name")} />
              {formik.touched.name && formik.errors.name && <div className="field-error">{formik.errors.name}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Coupon type</label>
              <div className="btn-group">
                <button type="button" disabled={!!editItem} className={"btn " + (formik.values.type == "percentage" ? "btn-active" : "")} onClick={() => formik.setFieldValue("type", "percentage")}>Percentage discount</button>
                <button type="button" disabled={!!editItem} className={"btn " + (formik.values.type == "fixed" ? "btn-active" : "")} onClick={() => formik.setFieldValue("type", "fixed")}>Fixed price discount</button>
              </div>
            </div>

            {formik.values.type == "percentage" && (
              <div className="form-group">
                <label className="form-label">Percentage off</label>
                <div className="input-group">
                  <input type="number" step=".01" disabled={!!editItem} placeholder="Price" className={getInputClassName(formik, "amount", "hide-spinner form-control-mw-170")} {...formik.getFieldProps("amount")} />
                  <span className="input-group-append">
                    <span className="input-group-text">%</span>
                  </span>
                </div>

                {formik.touched.amount && formik.errors.amount && <div className="field-error">{formik.errors.amount}</div>}
              </div>
            )}

            {formik.values.type == "fixed" && (
              <div className="form-group">
                <label className="form-label">Amount off</label>
                <div className="d-flex">
                  <div className="flex-auto mr-3">
                    <select className="custom-select" disabled={!!editItem} {...formik.getFieldProps("currency")}>
                      <option value="usd">USD</option>
                      <option value="eur">EUR</option>
                      <option value="gbp">GBP</option>
                      <option value="aud">AUD</option>
                      <option value="pln">PLN</option>
                      <option value="sek">SEK</option>
                      <option value="chf">CHF</option>
                    </select>
                  </div>

                  <div className="flex-auto mr-3">
                    <input type="number" step=".01" disabled={!!editItem} placeholder="Price" className={getInputClassName(formik, "amount", "hide-spinner form-control-mw-170")} {...formik.getFieldProps("amount")} />
                  </div>
                </div>

                {formik.touched.amount && formik.errors.amount && <div className="field-error">{formik.errors.amount}</div>}
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => onHide()}>Cancel</button>
            <button type="button" className={"btn btn-primary " + (saving ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={() => formik.submitForm()}>{editItem ? "Save coupon" : "Add coupon"}</button>
          </Modal.Footer>
        </>}
      </Formik>
    </Modal>
  );
}

function CouponDetailsModal({ product, item, show, onHide }) {
  let [data, setData] = useState([]),
    [loading, setLoading] = useState(true),
    history = useHistory();

  async function loadData() {
    setLoading(true);
    setData([]);

    let res = await api.product.getSales({
      couponId: item._id
    });

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setData(res.data);
  }

  function listenerOnClick(ev, id) {
    ev.preventDefault();
    history.push("/contacts/" + id + "/edit");
  }

  useEffect(() => {
    if(item)
      loadData();
  }, [item]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      centered
      className="coupon-details-modal"
      enforceFocus={false}>
      <Modal.Header>
        <h1 className="m-0">
          {item?.name.toUpperCase()}
        </h1>
      </Modal.Header>
      <Modal.Body>
        {loading
          ? (
            <div className="loading-block spinner" />
          )
          : (
            <div className="table-responsive">
              <table className="table table-head-custom">
                <thead>
                  <tr>
                    <th className="pl-0">Name</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>Plan name</th>
                  </tr>
                </thead>
                <tbody>
                  {!data.length && (
                    <tr>
                      <td colSpan="10" className="text-center">No data available.</td>
                    </tr>
                  )}
                  {data.map((item, i) => (
                    <tr key={i}>
                      <td className="pl-0 text-wrap">
                        <a href="#" className="text-inherit" onClick={ev => listenerOnClick(ev, item.listener.id)}>
                          {item.listener.name}
                        </a>
                      </td>
                      <td>
                        <a href="#" className="text-inherit" onClick={ev => listenerOnClick(ev, item.listener.id)}>
                          {item.listener.email}
                        </a>
                      </td>
                      <td>
                        {moment(item.date).locale("en").format("MMM D, YYYY")}
                      </td>
                      <td>
                        {item.price.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-secondary mr-2" onClick={() => onHide()}>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(connect(
  store => ({
    currentUser: store.auth.user,
    productObj: (store.product || []).product || {},
  }),
  dispatch => ({
    ...actions.product,
    dispatch,
  })
)(SalesPageSettings));