export default {
    translation: {
        "Coupon Code (optional)": "Coupon Code (optional)",
        "/ wk": "/ wk",
        "/ mo": "/ mo",
        "/ yr": "/ yr",
        "Click for Access": "Click for Access",
        "Buy Now": "Buy Now",
        "Select plan": "Select plan",
        "{{episodes}} episodes - free": "{{episodes}} episodes - free",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} episodes - {{displayPrice}}",
        "All": "All",
        "Audio Feeds": "Audio Feeds",
        "Category": "Category",
        "There are no shows in this category.": "There are no shows in this category.",
        "The creator doesn’t have any public audio feeds yet.": "The creator doesn’t have any public audio feeds yet.",
        "About me": "About me",
        "My audio feeds": "My audio feeds",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.",
        "Powered by Hiro.fm": "Powered by Hiro.fm",
        "Start your own private audio product": "Start your own private audio product",
        "episodes": "episodes",
        "Free": "Free",
        "/ week": "/ week",
        "/ month": "/ month",
        "/ year": "/ year",
        "one-time payment": "one-time payment",
        "per week": "per week",
        "per month": "per month",
        "per year": "per year",
        "Products": "Products",
        "Episodes": "Episodes",
        "Listeners": "Listeners",
        "Downloads": "Downloads",
        "My activity": "My activity",
        "Created by:": "Created by:",
        "Last update:": "Last update:",
        "This audio feed doesn’t have any eposides yet.": "This audio feed doesn’t have any eposides yet.",
        "See more": "See more",
        "Please verify the credit card details.": "Please verify the credit card details.",
        "Payment": "Payment",
        "Your details": "Your details",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "Email": "Email",
        "Phone (optional)": "Phone (optional)",
        "Invalid phone number.": "Invalid phone number.",
        "Payment details": "Payment details",
        "Total payment": "Total payment",
        "Get Access": "Get Access",
        "Pay": "Pay",
        "Category:": "Category:",
        "Get Free Access": "Get Free Access",
        "Show currently unavailable": "Show currently unavailable",
        "Buy now for {{priceValue}}": "Buy now for {{priceValue}}",
        "Length": "Length",
        "reviews": "reviews",
        "About": "About",
        "Testimonials": "Testimonials",
        "My other private audio feeds": "My other private audio feeds",
        "Thank you!": "Thank you!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.",
        "Point your phone’s camera at the code below to access the private podcast.": "Point your phone’s camera at the code below to access the private podcast.",
        "Purchased item": "Purchased item",
        "Access Private Feed Now": "Access Private Feed Now",
        "Ohh no, something went wrong with your payment": "Ohh no, something went wrong with your payment",
        "Check your credit card details and try one more time.": "Check your credit card details and try one more time.",
        "Try one more time": "Try one more time",
        "Cancel": "Cancel",
        "Enter your first name.": "Enter your first name.",
        "Enter your last name.": "Enter your last name.",
        "Enter your email address.": "Enter your email address.",
        "Invalid email address.": "Invalid email address.",
        "Product not found.": "Product not found.",
        "Payment failed.": "Payment failed.",
        "Fill all the fields.": "Fill all the fields.",
        "The email address is invalid.": "The email address is invalid.",
        "Invalud product ID.": "Invalud product ID.",
        "Access denied to product.": "Access denied to product.",
        "Show not available.": "Show not available.",
        "Terms of service": "Terms of service",
        "Privacy policy": "Privacy policy",
        "Pay what you want": "Pay what you want",
        "{{episodes}} episodes - {{displayPrice}} suggested price": "{{episodes}} episodes - {{displayPrice}} suggested price",
        "{{episodes}} episodes - {{displayPrice}} one time payment": "{{episodes}} episodes - {{displayPrice}} one time payment",
        "Enter the amount you want to pay.": "Enter the amount you want to pay.",
        "The amount must be greater than the minimum price.": "The amount must be greater than the minimum price.",
        "Suggested price": "Suggested price"
    }
};