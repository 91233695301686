import React, { useEffect, useState } from 'react';
import api from '../../../../redux/api';
import PublicPageLayout from '../../generic/PublicPageLayout';
import { ProductLogo } from '../../../../_metronic/layout/components/productLogo';
import SVG from 'react-inlinesvg';
import {
  toAbsoluteUrl,
  nl2br,
  isValidUrl,
} from '../../../../_metronic/_helpers';
import {
  formatLength,
  Stars,
  getProductUrl,
  AuthorSocialIcons,
  getAuthorUrl,
} from '../../../helpers';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { PriceRange } from '../../author/components/productWidget';
import CompletePaymentFlow from '../payment-signup/payment-signup-form/CompletePaymentFlow';
import { EpisodeList } from './EpisodeList';
import useAutosizeEmbed from '../../../helpers/useAutosizeEmbed';

require('dotenv').config();

export function SalesPageContent({ isEmbed, productId, currentUser, match }) {
  const [product, setProduct] = useState(null),
    [author, setAuthor] = useState(null),
    [expandedReviews, setExpandedReviews] = useState([]),
    [expandedFaq, setExpandedFaq] = useState([]),
    [isOwn, setIsOwn] = useState(false),
    [loading, setLoading] = useState(true),
    [buyModal, setBuyModal] = useState(false),
    [stripeObject, setStripeObject] = useState(null),
    { t, i18n } = useTranslation();

  useAutosizeEmbed();

  async function init() {
    setLoading(true);

    const filter = productId ? { id: productId } : { uri: match?.params?.uri };

    let res = await api.salesPage.getProduct(filter);
    if(!res.success || !res.data) {
      window.location.href = '/404';
      return;
    }

    setProduct(res.data.product);
    setAuthor(res.data.author);
    setLoading(false);

    document.querySelector('#root').scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    init();
  }, [match?.params?.uri]);

  useEffect(() => {
    setIsOwn(author && currentUser && author._id == currentUser._id);
  }, [currentUser, author]);

  useEffect(() => {
    (async () => {
      if(
        !stripeObject &&
        author &&
        author.stripeConnected &&
        author.stripeConnected.stripeUserId
      ) {
        let res = await loadStripe(process.env.REACT_APP_STRIPE_KEY, {
          stripeAccount: author.stripeConnected.stripeUserId,
        });
        setStripeObject(res);
      }
    })();
  }, [author]);

  useEffect(() => {
    if(product && author)
      i18n.changeLanguage(
        product.salesPageLanguage || author.salesPageLanguage || 'en'
      );
  }, [product, author]);

  if(!product || !author)
    return <PublicPageLayout className='sales-page' loading />;

  let stripeIsConnected = author.stripeConnected,
    authorUrl = getAuthorUrl(author);

  let isFree = product.prices.length > 0 && !product.prices.find((p) => p.type != "free");

  let isPayWhatYouWant = product.prices.length > 0 && !product.prices.find((p) => p.type != "pay-what-you-want");

  let isSinglePrice = product.prices.length <= 1;

  let canSell = isFree || (product.prices.length > 0 && stripeIsConnected);

  let isDraft =
    product.draft ||
    product.systemDisabled ||
    !author.enabled ||
    !product.salesPageLive ||
    !author.planFeatures.salesPage;

  document.title =
    product.name +
    (author.firstName
      ? ' - ' +
      author.firstName +
      (author.lastName ? ' ' + author.lastName : '')
      : '') +
    ' - Hiro';

  let reviewAverage = null;
  if(
    product.salesPage &&
    product.salesPage.reviews &&
    product.salesPage.reviews.length
  ) {
    product.salesPage.reviews.forEach((r) => (reviewAverage += r.rating));
    reviewAverage /= product.salesPage.reviews.length;
    reviewAverage = reviewAverage.toFixed(
      reviewAverage == Math.round(reviewAverage) ? 0 : 1
    );
  }

  let otherPodcasts = [];
  for(let i = 0; i < author.products.length; i++) {
    let p = author.products[i];
    if(p._id == product._id || p.draft || !p.salesPageLive || p.unlisted)
      continue;

    if(
      product.categoryFilter &&
      product.categoryId &&
      p.categoryId != product.categoryId
    )
      continue;

    otherPodcasts.push(
      <div className='col-6 col-md-3' key={i}>
        <div className='product'>
          <a href={getProductUrl(p)}>
            <ProductLogo product={p} className='icon' />
            <p className='title'>{p.name}</p>
            <p className='description'>{p.description}</p>
            <PriceRange product={p} />
          </a>
        </div>
      </div>
    );
  }

  let breadcrumb = (
    <>
      <a href={authorUrl}>
        {author.firstName
          ? author.firstName + (author.lastName ? ' ' + author.lastName : '')
          : author.email}
      </a>{' '}
      &nbsp;/ <span>{product.name}</span>
    </>
  );

  return (
    <PublicPageLayout
      isTestMode={author.paymentTestMode}
      logo={
        author &&
        author.logo &&
        author.planFeatures.fullSalesPage &&
        author.logo.imageUrl
      }
      author={author}
      className={"sales-page " + (isEmbed ? "card m-0" : "")}
      color={product.color}
      breadcrumb={breadcrumb}
      loading={loading}
      isEmbed={isEmbed}
    >
      <div className='col-12 breadcrumb-mobile d-md-none'>{breadcrumb}</div>

      {isDraft && (
        <div className='col-12'>
          <div className='alert alert-warning d-flex align-items-center flex-row mb-10'>
            <SVG
              src={toAbsoluteUrl('/media/svg/icons/Code/Warning-2.svg')}
              className='svg-icon'
            />
            <div>
              <h4>Your page is in preview mode.</h4>
              Only you can see this page because{' '}
              {(() => {
                if(!author.planFeatures.salesPage)
                  return 'your current plan doesn’t include sales pages';
                if(product.draft && !product.salesPageLive)
                  return 'your product and the sales page are drafts';
                if(product.draft) return 'your product is a draft';
                if(!product.salesPageLive) return "it's a draft";
              })()}
              .{' '}
              <a
                href={
                  process.env.REACT_APP_SERVER_URL +
                  'products/' +
                  product._id +
                  '/sales-page'
                }
              >
                Go to settings
              </a>
            </div>
          </div>
        </div>
      )}

      {isOwn &&
        (product.prices.length == 0 || (!isFree && !stripeIsConnected)) && (
          <div className='col-12'>
            <div className='alert alert-warning d-flex align-items-center flex-row mb-10'>
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Code/Warning-2.svg')}
                className='svg-icon'
              />
              <div>
                <h4>
                  Set the price and connect Stripe to enable access to your
                  product.
                </h4>
                You have to add at least one price, and connect your Stripe
                account if not free, before you can get new listeners.{' '}
                <a
                  href={
                    process.env.REACT_APP_SERVER_URL +
                    'products/' +
                    product._id +
                    '/sales-page'
                  }
                >
                  Go to settings
                </a>
              </div>
            </div>
          </div>
        )}

      <div className='col-lg-7'>
        <div className='content-item product-details d-lg-flex'>
          <ProductLogo product={product} />
          <div className='flex-grow-1 mt-1'>
            <div className='summary d-flex align-items-center'>
              <div className='flex-grow-1'>
                {t('Created by:')}{' '}
                <a href={authorUrl} className='author-link themed'>
                  {author.firstName
                    ? author.firstName +
                    (author.lastName ? ' ' + author.lastName : '')
                    : author.email}
                </a>
              </div>
              {product.categoryId && author.categories.length && (
                <div>
                  <span>{t('Category:')} </span>
                  <a
                    href={authorUrl + '?category=' + product.categoryId}
                    className='text-inherit'
                  >
                    {
                      author.categories.find((c) => c._id == product.categoryId)
                        .name
                    }
                  </a>
                </div>
              )}
            </div>
            <h1>{product.name}</h1>
            <div className='product-description'>
              {nl2br(product.description)}
            </div>

            {author.contactsMaxedOut ? (
              <button
                type='button'
                className='btn btn-primary btn-buy disabled'
              >
                {t('Show currently unavailable')}
              </button>
            ) : (
              <>
                {isFree && (
                  <button
                    type='button'
                    className='btn btn-buy'
                    onClick={(e) => setBuyModal(true)}
                  >
                    {t('Click for Access')}
                    <br />
                    <span className='price'>{t('Free')}</span>
                  </button>
                )}

                {isPayWhatYouWant && (
                  <button
                    type='button'
                    className='btn btn-buy'
                    onClick={(e) => setBuyModal(true)}
                  >
                    {t('Click for Access')}
                    <br />
                    <span className='price'>{t("Pay what you want")}</span>
                  </button>
                )}

                {!isFree && !isPayWhatYouWant && isSinglePrice && (
                  <button
                    type='button'
                    disabled={!canSell}
                    className='btn btn-buy'
                    onClick={(e) => setBuyModal(true)}
                  >
                    {t('Click for Access')}
                    <br />
                    <PriceRange product={product} />
                  </button>
                )}

                {!isFree && !isPayWhatYouWant && !isSinglePrice && (
                  <button
                    type='button'
                    className='btn btn-buy'
                    onClick={(e) => setBuyModal(true)}
                  >
                    {t('Click for Access')}
                    <br />
                    <PriceRange product={product} />
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-4'>
            <div className='content-item product-stats mb'>
              {product.episodes.length} <label>{t('Episodes')}</label>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='content-item product-stats mb'>
              {formatLength(product.totalDuration)} <label>{t('Length')}</label>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='content-item product-stats'>
              <Stars value={reviewAverage || 0} />
              <label>
                {product.salesPage.reviews.length} {t('reviews')}
              </label>
            </div>
          </div>
        </div>

        {!product.episodes.length ? (
          ''
        ) : (
          <div className='content-item d-lg-none'>
            <EpisodeList product={product} mobile />
          </div>
        )}

        {product.salesPage.about && (
          <div className='content-item'>
            <h1>{t('About')}</h1>
            <div
              className='has-user-html'
              dangerouslySetInnerHTML={{ __html: product.salesPage.about }}
            />
          </div>
        )}

        {!product.salesPage.reviews.length ? (
          ''
        ) : (
          <div className='content-item'>
            <h1>{t('Testimonials')}</h1>
            <div className='reviews-list'>
              {(() => {
                let list = [];
                for(let i = 0; i < product.salesPage.reviews.length; i++) {
                  let item = product.salesPage.reviews[i];

                  list.push(
                    <div
                      key={i}
                      className={
                        'review-item ' +
                        (expandedReviews.includes(i) ? 'expanded' : '')
                      }
                    >
                      <div className='d-flex align-items-center'>
                        <div className='btn-expand-col d-sm-none'>
                          <button
                            type='button'
                            className='btn-expand'
                            onClick={() => {
                              if(expandedReviews.includes(i))
                                setExpandedReviews(
                                  expandedReviews.filter((x) => x != i)
                                );
                              else
                                setExpandedReviews(expandedReviews.concat(i));
                            }}
                          />
                        </div>
                        <div className='flex-1 mw0'>
                          <div className='d-flex align-items-center'>
                            <img
                              src={
                                item.pictureUrl ||
                                toAbsoluteUrl('/media/users/blank.png')
                              }
                              className='review-cover'
                            />
                            <div className='flex-1 review-name'>
                              <strong>
                                {item.firstName +
                                  (item.lastName ? ' ' + item.lastName : '')}
                              </strong>
                            </div>
                            <div className='review-stars'>
                              <Stars value={item.rating} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='expanded-text'>{nl2br(item.review)}</div>
                    </div>
                  );
                }
                return list;
              })()}
            </div>
          </div>
        )}

        {!product.salesPage.faq.length ? (
          ''
        ) : (
          <div className='content-item faq-list'>
            <h1>{t('FAQ')}</h1>
            {(() => {
              let list = [];
              for(let i = 0; i < product.salesPage.faq.length; i++) {
                let item = product.salesPage.faq[i];

                list.push(
                  <div
                    key={i}
                    className={
                      'faq-item ' + (expandedFaq.includes(i) ? 'expanded' : '')
                    }
                  >
                    <div className='d-flex align-items-center'>
                      <div className='btn-expand-col'>
                        <button
                          type='button'
                          className='btn-expand'
                          onClick={() => {
                            if(expandedFaq.includes(i))
                              setExpandedFaq(expandedFaq.filter((x) => x != i));
                            else setExpandedFaq(expandedFaq.concat(i));
                          }}
                        />
                      </div>
                      <div className='flex-1'>
                        <strong>{item.question}</strong>
                      </div>
                    </div>
                    <div className='expanded-text'>{nl2br(item.response)}</div>
                  </div>
                );
              }
              return list;
            })()}
          </div>
        )}

        {!isEmbed && (
          <>
            <div className='content-item author-summary'>
              <div className='author-bio'>
                <a href={authorUrl} className='profile-pic'>
                  <img
                    src={
                      author.pictureUrl ||
                      toAbsoluteUrl('/media/users/blank.png')
                    }
                  />
                </a>
                <div className='author-bio-bg'>
                  <h1>
                    <a className='themed' href={authorUrl}>
                      {author.firstName
                        ? author.firstName +
                        (author.lastName ? ' ' + author.lastName : '')
                        : author.email}
                    </a>
                  </h1>
                  {author.website && isValidUrl(author.website) && (
                    <a href={author.website} target='_blank' className='themed'>
                      {author.website.replace(/^https?:\/\//i, '')}
                    </a>
                  )}
                  <AuthorSocialIcons author={author} />
                </div>
              </div>

              {author.bio && (
                <>
                  <h2>{t('About me')}</h2>
                  <div dangerouslySetInnerHTML={{ __html: author.bio }} />
                </>
              )}

              {!otherPodcasts.length ? (
                ''
              ) : (
                <>
                  <h2>{t('My other private audio feeds')}</h2>
                  <div className='row products-list'>{otherPodcasts}</div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {!product.episodes.length ? (
        ''
      ) : (
        <div className='d-none d-lg-block col-md-5'>
          <div className='content-item'>
            <EpisodeList product={product} desktop />
            <div className='hr' />
            <div className='buy-button-container'>
              {author.contactsMaxedOut ? (
                <button
                  type='button'
                  className='btn btn-primary btn-buy disabled'
                >
                  {t('Show currently unavailable')}
                </button>
              ) : (
                <>
                  {isFree && (
                    <button
                      type='button'
                      className='btn btn-primary btn-buy'
                      onClick={(e) => setBuyModal(true)}
                    >
                      {t('Click for Access')}
                      <br />
                      <span className='price'>{t('Free')}</span>
                    </button>
                  )}

                  {isPayWhatYouWant && (
                    <button
                      type='button'
                      className='btn btn-buy'
                      onClick={(e) => setBuyModal(true)}
                    >
                      {t('Click for Access')}
                      <br />
                      <span className='price'>{t("Pay what you want")}</span>
                    </button>
                  )}

                  {!isFree && !isPayWhatYouWant && isSinglePrice && (
                    <button
                      type='button'
                      disabled={!canSell}
                      className='btn btn-primary btn-buy'
                      onClick={(e) => setBuyModal(true)}
                    >
                      {t('Click for Access')}
                      <br />
                      <PriceRange product={product} />
                    </button>
                  )}

                  {!isFree && !isPayWhatYouWant && !isSinglePrice && (
                    <button
                      type='button'
                      className='btn btn-primary btn-buy'
                      onClick={(e) => setBuyModal(true)}
                    >
                      {t('Click for Access')}
                      <br />
                      <PriceRange product={product} />
                    </button>
                  )}

                  {!isFree && (
                    <img
                      src={toAbsoluteUrl('/media/def-image/guarantee.svg')}
                      alt='Payment method'
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <CompletePaymentFlow
        product={product}
        author={author}
        stripeObject={stripeObject}
        showForm={buyModal}
        onHideForm={() => setBuyModal(false)}
        isModal={true}
        redirectOnSuccess={true}
      />
    </PublicPageLayout>
  );
}

export default SalesPageContent;
