import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { Link, useLocation } from "react-router-dom";
import ModalEditCustomer from "./generic/generic/ModalEditCustomer";
import BulkUploadListeners from "./generic/BulkUploadListeners";
import moment from "moment";
import { checkLimits } from "../../../plans";
import ConfirmModal from "../../modals/ConfirmModal";
import SVG from "react-inlinesvg";
import { Switch, Tooltip } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { relativeDate, toastMessage, updateCurrentUser } from "../../../helpers";
import _ from "lodash";
import ListenerActivityModal from "./ListenerActivityModal";
import BulkUploadListenersModal from "./generic/BulkUploadListenersModal";
import NewListenerDropdown from "../../../layout/dropdowns/NewListenerDropdown";
import OnHoldBadge from "../../generic/OnHoldBadge";
import Lottie from "react-lottie";
import animationData from "../../../layout/lottie/empty-contacts.json";
import { CSVDownload } from "react-csv";

let csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Is SMS", key: "isSms" },
  { label: "Is WhatsApp", key: "isWhatsapp" },
  { label: "Is sample", key: "isSample" },
  { label: "Tags", key: "tags" },
  { label: "Device", key: "deviceLabel" },
  { label: "Player", key: "playerName" },
  { label: "Created on", key: "createdAt" },
  { label: "Link activated", key: "feedFirstAccessedAt" },
  { label: "Last listened", key: "lastListens" },
  { label: "Episodes", key: "listens" },
  { label: "Access", key: "access" },
  { label: "Feed URL", key: "url" }
];

let subscriptionsToUpdate,
  subscriptionsUpdateAction;

function SortIcon({ asc }) {
  return (
    <svg className={"sort-icon icon-transform-" + (asc ? "asc" : "desc")} focusable="false" viewBox="0 0 24 24">
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    </svg>
  );
}

function Listeners({ match, history, product, products, episodes, user, dispatch, setProduct }) {
  let [showEditModal, setShowEditModal] = useState(false),
    [showBulkModal, setShowBulkModal] = useState(false),
    [editListenerId, setEditListenerId] = useState(null),
    [loading, setLoading] = useState(true),
    [actionLoading, setActionLaoding] = useState(null),
    [selectedListeners, setSelectedListeners] = useState([]),
    [search, setSearch] = useState(),
    [page, setPage] = useState(1),
    [rowsPerPage, setRowsPerPage] = useState(10),
    [listeners, setListeners] = useState([]),
    [stats, setStats] = useState({
      total: "-",
      recent: "-",
      active: "-",
      completed: "-"
    }),
    [sortAsc, setSortAsc] = useState(true),
    [sortBy, setSortBy] = useState(),
    [csvData, setCsvData] = useState(null),
    [showConfirmBlockListener, setShowConfirmBlockListener] = useState(null),
    [showConfirmUnblockListener, setShowConfirmUnblockListener] = useState(null),
    [showConfirmDeleteListeners, setShowConfirmDeleteListeners] = useState(null),
    [showConfirmDeleteListener, setShowConfirmDeleteListener] = useState(null),
    [showConfirmUpdateSubscriptions, setShowConfirmUpdateSubscriptions] = useState(null),
    [showListenerActivity, setShowListenerActivity] = useState(null),
    [showConfirmReset, setShowConfirmReset] = useState(null),
    [pages, setPages] = useState(1),
    [firstLoad, setFirstLoad] = useState(true),
    location = useLocation(),
    searchTimerRef = useRef(),
    [exportLoading, setExportLoading] = useState();

  useState(() => {
    let defaultSearch = new URLSearchParams(location.search).get("search");
    if(defaultSearch)
      setSearch(defaultSearch);
  }, []);

  let loadData = async () => {
    setSelectedListeners([]);
    let res = await api.listener.getListeners(match.params.id, { page, rowsPerPage, search, sortBy, sortAsc: sortAsc ? "asc" : "desc" });
    setPages(res.pages);
    setStats(res.stats);
    if(res.data.length)
      setFirstLoad(false);
    dispatch(setProduct(await api.product.getProduct(match.params.id)));
    // dispatch(setListeners(res.data));
    setListeners(res.data);
    await updateCurrentUser(dispatch);
    setLoading(false);
  };

  async function exportOnClick() {
    setExportLoading(true);

    let res = await api.listener.getListeners(match.params.id, { page, export: true, search, sortBy, sortAsc: sortAsc ? "asc" : "desc" });

    setExportLoading(false);

    setCsvData(res.data.map(item => ({
      name: item.name,
      email: item.email,
      phone: item.phone,
      isSms: item.isSmsPhoneNumber ? "Yes" : "",
      isWhatsapp: item.isWhatsappPhoneNumber ? "Yes" : "",
      isSample: item.isSample ? "Yes" : "",
      deviceLabel: item.deviceLabel || "",
      tags: Array.isArray(item.tags)
        ? item.tags.join(", ")
        : "",
      playerName: item.playerName,
      createdAt: item.createdAt,
      feedFirstAccessedAt: item.feedFirstAccessedAt ? moment(item.feedFirstAccessedAt).locale("en").format("MM/DD/YY") : "",
      lastListens: item.lastListens ? moment(item.lastListens).locale("en").format("MM/DD/YY") : "",
      listens: item.listens,
      access: !item.blocked ? "Yes" : "No",
      url: item.feedUrl
    })));
    
    setTimeout(() => setCsvData(null), 1000);
  }

  let newListener = async () => {
    if(await checkLimits.canAddContact(user))
      editListener(null);
  };

  let editListener = listener => {
    setEditListenerId(listener ? listener._id : null);
    setShowEditModal(true);
  };

  let columnClick = column => {
    if(sortBy == column) {
      setSortAsc(v => !v);
    } else {
      setSortAsc(true);
      setSortBy(column);
    }
  };

  let resendEmailListeners = async () => {
    if(product.draft != 0 || !product.episodes || !product.episodes.length || !product.episodes.filter(e => e.draft != 1).length)
      return toastMessage.error("Unable to send message: Show is not ready for release.");

    setActionLaoding("resend");

    let promises = [];

    for(let id of selectedListeners) {
      let listener = listeners.filter(i => i._id == id)[0];

      promises.push(api.listener.setListenerAccessEmail({
        productId: product._id,
        listenerId: listener._id
      }));
    }

    Promise.all(promises).then(results => {
      setActionLaoding(null);

      if(results.some(r => r.emailError === true))
        toastMessage.error(<>The email couldn’t be sent because it’s not set up correctly. <a href="https://hiro.fm/kb/why-arent-my-contacts-listeners-receiving-emails-or-sms-messages/" target="_blank">Get help fixing this</a></>, {
          autoClose: 10000
        });
      else
        toastMessage.success(`Access sent successfully.`);
    });
  };

  let deleteListeners = () => {
    setShowConfirmDeleteListeners(true);
  };

  let toggleAll = ev => {
    if(!ev.target.checked)
      return setSelectedListeners([]);

    let ids = [];
    listeners.forEach(item => ids.push(item._id));
    setSelectedListeners(ids);
  };

  let toggleListener = (ev, listener) => {
    if(!ev.target.checked)
      setSelectedListeners(v => v.filter(i => i != listener._id));
    else if(!selectedListeners.includes(listener._id))
      setSelectedListeners(v => (v.push(listener._id), [...v]));
  };

  let blockedOnChange = (ev, listener) => {
    if(ev.target.checked)
      setShowConfirmUnblockListener(listener);
    else
      setShowConfirmBlockListener(listener);
  };

  async function grantFullAccess(listener) {
    setActionLaoding("menu_" + listener._id);

    let res = await api.listener.unlockSampleProduct({
      productId: product._id,
      listenerId: listener._id
    });

    await loadData();

    setActionLaoding(null);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Listener updated successfully.");
  }

  let resendEmailListener = async listener => {
    setActionLaoding("menu_" + listener._id);

    let res = await api.listener.setListenerAccessEmail({
      productId: product._id,
      listenerId: listener._id
    });

    setActionLaoding(null);

    if(!res || !res.success)
      return;

    if(res.emailError)
      toastMessage.error(<>The email couldn’t be sent because it’s not set up correctly. <a href="https://hiro.fm/kb/why-arent-my-contacts-listeners-receiving-emails-or-sms-messages/" target="_blank">Get help fixing this</a></>, {
        autoClose: 10000
      });
    else
      toastMessage.success("Access sent successfully.");
  };

  let deleteListener = listener => {
    setShowConfirmDeleteListener(listener);
  };

  let confirmBlockUnblockListener = async block => {
    let listener;
    if(block) {
      listener = showConfirmBlockListener;
      setShowConfirmBlockListener(null);
    } else {
      listener = showConfirmUnblockListener;
      setShowConfirmUnblockListener(null);
    }

    setActionLaoding("block_" + listener._id);

    listener.blocked = !listener.blocked;

    subscriptionsToUpdate = listener.isSubscription
      ? [listener]
      : [];

    let res = await api.listener.blockListener({
      data: {
        isBlock: block
      },
      productId: product._id,
      listenerId: listener._id
    });

    setActionLaoding(null);

    if(!res || !res.success)
      return;

    loadData();

    if(subscriptionsToUpdate.length) {
      if(block) {
        setShowConfirmUpdateSubscriptions("pause their subscription");
        subscriptionsUpdateAction = "pause";
      } else {
        setShowConfirmUpdateSubscriptions("resume their subscription");
        subscriptionsUpdateAction = "resume";
      }
    } else {
      toastMessage.success("Listener " + (block ? "blocked" : "unblocked") + " successfully.");
    }
  };

  let confirmDeleteListeners = () => {
    setShowConfirmDeleteListeners(false);
    setActionLaoding("delete");

    let promises = [];

    subscriptionsToUpdate = [];
    subscriptionsUpdateAction = "cancel";

    selectedListeners.forEach(id => {
      let listener = listeners.filter(i => i._id == id)[0];

      promises.push(api.listener.deleteListener({
        productId: product._id,
        listenerId: listener._id
      }));

      if(listener.isSubscription)
        subscriptionsToUpdate.push(listener);
    });

    Promise.all(promises).then(() => {
      setSelectedListeners([]);
      setActionLaoding(null);

      loadData();

      if(subscriptionsToUpdate.length) {
        setShowConfirmUpdateSubscriptions("cancel their subscriptions");
      } else {
        toastMessage.success("Listeners deleted successfully.");
      }
    });
  };

  let confirmDeleteListener = async () => {
    let listener = showConfirmDeleteListener;

    setShowConfirmDeleteListener(false);
    setActionLaoding("menu_" + listener._id);

    let res = await api.listener.deleteListener({
      productId: product._id,
      listenerId: listener._id
    });

    setActionLaoding(null);

    loadData();

    if(!res || !res.success)
      return;

    if(listener.isSubscription) {
      subscriptionsToUpdate = [listener];
      subscriptionsUpdateAction = "cancel";
      setShowConfirmUpdateSubscriptions("cancel their subscription");
    } else {
      toastMessage.success("Listener deleted successfully.");
    }
  };

  let confirmUpdateSubscriptions = async () => {
    setShowConfirmUpdateSubscriptions(null);
    setActionLaoding(subscriptionsToUpdate.length == 1 ? "menu_" + subscriptionsToUpdate[0]._id : "delete");

    let res = await api.listener.updateSubscriptions(subscriptionsToUpdate.map(i => i.subscriptionId), subscriptionsUpdateAction);
    if(res && res.success)
      toastMessage.success("Subscriptions updated succefully!");

    setActionLaoding(null);
  };

  async function toggleAccessForever(listener) {
    setActionLaoding("menu_" + listener._id);

    let res = await api.listener.toggleAccessForever(product._id, listener._id);

    if(!res || !res.success) {
      setActionLaoding(null);
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");
    }

    toastMessage.success("Listener updated!");

    await loadData();

    setActionLaoding(null);
  }

  function resetAccess(listener) {
    setShowConfirmReset(listener);
  }

  async function confirmResetAccess() {
    let listener = showConfirmReset;
    setActionLaoding("menu_" + listener._id);
    setShowConfirmReset(null);

    let res = await api.listener.resetAccess(product._id, listener._id);

    if(!res || !res.success) {
      setActionLaoding(null);
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");
    }

    toastMessage.success("The listener has been reset!");

    await loadData();

    setActionLaoding(null);
  }

  function searchOnInput(ev) {
    setSearch(ev.target.value);
  }

  useEffect(() => {
    searchTimerRef.current = setTimeout(() => {
      loadData();
    }, 500);

    return () => {
      clearTimeout(searchTimerRef.current);
    };
  }, [search]);

  useEffect(() => {
    loadData();
  }, [page, rowsPerPage, sortBy, sortAsc]);

  useEffect(() => {
    if(location.hash == "#edit")
      newListener();
    if(location.hash == "#bulk-upload")
      setShowBulkModal(true);
  }, [location]);

  let ActivityLink = ({ listener, children }) => (
    <a href="#" onClick={ev => (ev.preventDefault(), setShowListenerActivity(listener))} className="fw-semibold text-primary">{children}</a>
  );

  let Player = ({ listener }) => (
    <span className={listener.device ? "device-" + listener.device : ""}>
      {listener.playerName || "-"}
    </span>
  );

  return (
    <>
      <h1>
        Listeners
        <NewListenerDropdown productId={match.params.id} className="inline" toggleClassName="btn-plus" />
      </h1>

      <ModalEditCustomer
        show={showEditModal}
        onHide={() => (setShowEditModal(false), loadData())}
        productId={match.params.id}
        studentId={editListenerId}
        user={user} />

      <div className={"listeners listeners-table" + (loading ? " loading" : "") + (!episodes.length || !listeners.length ? " empty-state" : "")}>
        {loading
          ? <div className="loading-block spinner spinner-lg" />
          : (
            <>
              {!!episodes.length && !listeners.length && firstLoad && (
                <div className="container-small">
                  <div className="empty-lottie">
                    <Lottie options={{
                      animationData,
                      loop: true,
                      autoplay: true
                    }} height={240} width={300} />
                  </div>
                  <div className="empty-content text-center">
                    <p className="mb-10">Let’s add some listeners to your audio feed!</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-secondary mr-2" onClick={newListener}>Add Single Listener</a>
                      <a className="btn btn-primary ml-2">
                        <BulkUploadListeners productId={match.params.id} callbackBulkUploadListeners={loadData} doNotSendAccess={product.draft == 1}>
                          Upload CSV File
                        </BulkUploadListeners>
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {!episodes.length && (
                <div className="container-small text-center">
                  <div className="empty-lottie">
                    <Lottie options={{
                      animationData,
                      loop: false,
                      autoplay: true
                    }} height={240} width={300} />
                  </div>
                  <div className="empty-content">
                    <p className="mb-10">You’ll be able to add listeners when you have at least one live episode.</p>
                    <Link to={"/products/" + match.params.id + "/episodes/create-single"} className="btn btn-primary mb-20" >Create Your First Episode</Link>
                  </div>
                </div>
              )}

              {((!!episodes.length && !!listeners.length) || !firstLoad) && (
                <div className="row listeners-item">
                  <div className="col-lg-12 col-xxl-12">
                    <div className="row stats stats-overview">
                      <div className="col-lg-3">
                        <div className="card">
                          <h3>All Time Listeners</h3>
                          <label>{stats.total}</label>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <h3>New Listeners</h3>
                          <label>{stats.recent}</label>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <h3>Active Listeners</h3>
                          <label>{stats.active}</label>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <h3>Completed</h3>
                          <label>{stats.completed}</label>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header border-0">
                        <div className="card-toolbar">
                          <input type="text" className="form-control search" placeholder="Search Listeners" value={search} onInput={searchOnInput} />
                          <div className="buttons">
                            <button className={"btn btn-primary-light " + (exportLoading ? "loading spinner" : "")} onClick={exportOnClick}>
                              Export
                            </button>
                            <button type="button" className={"btn btn-primary-light" + (actionLoading == "resend" ? " loading spinner" : "")} disabled={!selectedListeners.length || product.draft} onClick={resendEmailListeners}>
                              Send Access Email
                            </button>
                            <button type="button" onClick={deleteListeners} className={"btn btn-danger " + (actionLoading == "delete" ? " loading spinner" : "")} disabled={!selectedListeners.length}>
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <div className="table-responsive">
                          <table className="table table-head-custom table-vertical-center">
                            <thead>
                              <tr className="text-left">
                                <th className="pl-0">
                                  <label className="checkbox checkbox-lg checkbox-single">
                                    <input type="checkbox" value="1" onChange={toggleAll} />
                                    <span />
                                  </label>
                                </th>
                                <th className="pr-0" onClick={() => columnClick("name")}>
                                  {sortBy === "name" && <SortIcon asc={sortAsc} />}
                                  Name
                                </th>
                                <th onClick={() => columnClick("email")}>
                                  {sortBy === "email" && <SortIcon asc={sortAsc} />}
                                  Email
                                </th>
                                <th onClick={() => columnClick("phone")}>
                                  {sortBy === "phone" && <SortIcon asc={sortAsc} />}
                                  Phone
                                </th>
                                {/*
                                <th onClick={() => columnClick("tags")}>
                                  {sortBy === "tags" && <SortIcon asc={sortAsc} />}
                                  Tags
                                </th>
                                */}
                                <th onClick={() => columnClick("playerName")} className="listener-player-column">
                                  {sortBy === "playerName" && <SortIcon asc={sortAsc} />}
                                  Player
                                </th>
                                <th onClick={() => columnClick("createdAt")}>
                                  {sortBy === "createdAt" && <SortIcon asc={sortAsc} />}
                                  Created On
                                </th>
                                <th onClick={() => columnClick("feedFirstAccessedAt")}>
                                  {sortBy === "feedFirstAccessedAt" && <SortIcon asc={sortAsc} />}
                                  Link Activated
                                </th>
                                <th onClick={() => columnClick("lastListens")}>
                                  {sortBy === "lastListens" && <SortIcon asc={sortAsc} />}
                                  Last Listened
                                </th>
                                <th onClick={() => columnClick("listens")}>
                                  {sortBy === "listens" && <SortIcon asc={sortAsc} />}
                                  Episodes
                                </th>
                                <th onClick={() => columnClick("blocked")} >
                                  {sortBy === "blocked" && <SortIcon asc={sortAsc} />}
                                  Access
                                </th>
                                <th className="pr-0 text-right" style={{ minWidth: "50px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {listeners.map((listener, i) => (
                                <tr key={i}>
                                  <td className="pl-0">
                                    <label className="checkbox checkbox-lg checkbox-single">
                                      <input type="checkbox" checked={selectedListeners.includes(listener._id)} onChange={ev => toggleListener(ev, listener)} />
                                      <span />
                                    </label>
                                  </td>
                                  <td className="text-wrap">
                                    <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg" onClick={() => editListener(listener)}>
                                      {listener.name == "Anonymous"
                                        ? <span className="fw-normal text-muted">(No name)</span>
                                        : listener.name || (listener.firstName + (listener.lastName ? " " + listener.lastName : "")) || (listener.isSmsOptIn ? <span className="fw-normal text-muted">(SMS opt-in)</span> : <span className="fw-normal text-muted">(No name)</span>)}

                                      {listener.isSample && <span className="badge gray-badge ml-3">Sample</span>}
                                    </a>
                                  </td>
                                  <td className="text-wrap">
                                    {listener.email || <span className="fw-normal text-muted">(No email)</span>}
                                  </td>
                                  <td>
                                    {listener.phone || <span className="fw-normal text-muted">(No phone)</span>}

                                    {listener.phone && listener.isSmsPhoneNumber && <SVG src="/media/def-image/icons/mobile.svg" className="align-top mx-2 w-15px" />}

                                    {listener.phone && listener.isWhatsappPhoneNumber && <SVG src="/media/def-image/icons/whatsapp.svg" className="align-top mx-2 w-15px" />}
                                  </td>
                                  {/*
                                  <td className="text-wrap">
                                    {Array.isArray(listener.tags) && listener.tags.map(t => <span className="badge gray-badge me-2 my-1" key={t}>{t}</span>)}
                                  </td>
                                  */}
                                  <td className="listener-player-column">
                                    {listener.player == "hiro"
                                      ? (
                                        <ActivityLink listener={listener}>
                                          <Player listener={listener} />
                                        </ActivityLink>
                                      )
                                      : <Player listener={listener} />}
                                  </td>
                                  <td>
                                    {moment(listener.createdAt).locale("en").format("MMM D, YYYY")}
                                  </td>
                                  <td>
                                    {listener.feedFirstAccessedAt
                                      ? (
                                        <>
                                          {moment(listener.feedFirstAccessedAt).locale("en").format("MMM D, YYYY")}
                                        </>
                                      )
                                      : <span className="gray-badge">Not active</span>}
                                  </td>
                                  <td>
                                    <span className="gray-badge">
                                      {listener.lastListenedAt ? relativeDate(listener.lastListenedAt) : "Not yet played"}
                                    </span>
                                  </td>
                                  <td>
                                    {listener.player == "hiro"
                                      ? (
                                        <ActivityLink listener={listener}>{listener.listens}</ActivityLink>
                                      )
                                      : listener.listens}
                                  </td>
                                  <td>
                                    {listener.onHold
                                      ? (
                                        <OnHoldBadge user={user} />
                                      )
                                      : (
                                        <Tooltip title={(() => {
                                          if(!!product.draft)
                                            return "Product is not active. Make the product active and resend the email.";
                                          if(!product.episodes || !product.episodes.length || product.episodes.length === 0)
                                            return "The product has no live episodes. To give listeners access, add a live episode then resend the email.";
                                          return "";
                                        })()} placement="left" arrow classes={{ "tooltip": "text-center bg-secondary text-dark" }}>
                                          <span>
                                            <Switch onChange={ev => blockedOnChange(ev, listener)} checked={!listener.blocked && product.draft !== 1} value={listener.blocked} disabled={!!product.draft || actionLoading == "block_" + listener._id} />
                                          </span>
                                        </Tooltip>
                                      )}
                                  </td>
                                  <td className="pr-0 text-right">
                                    <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                      <Dropdown.Toggle className={actionLoading == "menu_" + listener._id ? " loading spinner spinner-dark" : ""}>
                                        <SVG src="/media/def-image/icons/dots-circle.svg" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                        <Dropdown.Item href="#" onClick={() => editListener(listener)}>
                                          <div className="icon">
                                            <SVG src="/media/def-image/icons/pencil.svg" />
                                          </div>
                                          Edit Details
                                        </Dropdown.Item>

                                        {!listener.onHold && !listener.isExpired && (
                                          <Dropdown.Item href="#" onClick={() => resendEmailListener(listener)} disabled={product.draft}>
                                            <div className="icon">
                                              <SVG src="/media/def-image/icons/email-2.svg" />
                                            </div>
                                            Resend Access Email
                                          </Dropdown.Item>
                                        )}

                                        {listener.isSample && (
                                          <Dropdown.Item href="#" onClick={() => grantFullAccess(listener)}>
                                            <div className="icon">
                                              <SVG src="/media/def-image/icons/enable.svg" />
                                            </div>
                                            Grant Full Access
                                          </Dropdown.Item>
                                        )}

                                        {(product.livePeriod == 1 || product.livePeriod == 2) && (
                                          <Dropdown.Item href="#" onClick={() => toggleAccessForever(listener)}>
                                            <div className="icon">
                                              <SVG src={listener.accessForever
                                                ? "/media/def-image/icons/gift-no.svg"
                                                : "/media/def-image/icons/gift-b.svg"} />
                                            </div>
                                            {listener.accessForever
                                              ? "Remove Access"
                                              : "Grant Lifetime Access"}
                                          </Dropdown.Item>
                                        )}

                                        {listener.canReset && (
                                          <Dropdown.Item href="#" onClick={() => resetAccess(listener)}>
                                            <div className="icon">
                                              <SVG src="/media/def-image/icons/reset.svg" />
                                            </div>
                                            Clear & Restart Listener
                                          </Dropdown.Item>
                                        )}

                                        <Dropdown.Item href="#" onClick={() => deleteListener(listener)}>
                                          <div className="icon">
                                            <SVG src="/media/def-image/icons/delete.svg" />
                                          </div>
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="card-footer border-0 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <label className="m-0">Page:</label>
                          <select className="custom-select w-auto ml-4" onChange={ev => setPage(ev.target.value)}>
                            {(() => {
                              let res = [];
                              for(let i = 1; i <= (pages || 1); i++)
                                res.push(<option key={i} selected={page == i} value={i}>{i}</option>);
                              return res;
                            })()}
                          </select>
                        </div>

                        <div className="d-flex align-items-center">
                          <label className="m-0">Rows:</label>
                          <select className="custom-select w-auto ml-4" onChange={ev => setRowsPerPage(ev.target.value)}>
                            <option value="10" selected={rowsPerPage == 10}>10</option>
                            <option value="20" selected={rowsPerPage == 20}>20</option>
                            <option value="50" selected={rowsPerPage == 50}>50</option>
                            <option value="100" selected={rowsPerPage == 100}>100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
      </div>

      <ConfirmModal
        show={showConfirmBlockListener != null}
        message={<>This will prevent the listener from accessing the audio feed.<br />Do you want to continue?</>}
        onConfirm={() => confirmBlockUnblockListener(true)}
        onCancel={() => setShowConfirmBlockListener(null)} />

      <ConfirmModal
        show={showConfirmUnblockListener != null}
        message={<>This will allow the listener to access the audio feed.<br />Do you want to continue?</>}
        onConfirm={() => confirmBlockUnblockListener(false)}
        onCancel={() => setShowConfirmUnblockListener(null)} />

      <ConfirmModal
        show={showConfirmDeleteListener}
        message="Do you want to delete the listener?"
        onConfirm={confirmDeleteListener}
        onCancel={() => setShowConfirmDeleteListener(false)} />

      <ConfirmModal
        show={showConfirmDeleteListeners}
        message="Do you want to delete the selected listeners?"
        onConfirm={confirmDeleteListeners}
        onCancel={() => setShowConfirmDeleteListeners(false)} />

      <ConfirmModal
        show={showConfirmUpdateSubscriptions}
        message={<>Success!<br />Do you want to {showConfirmUpdateSubscriptions} too?</>}
        onConfirm={confirmUpdateSubscriptions}
        onCancel={() => setShowConfirmUpdateSubscriptions(false)} />

      <ConfirmModal
        show={showConfirmReset}
        message="Please confirm you want to reset the listener. This will clear reset the access date, progress, and automations. This action cannot be undone."
        onConfirm={() => confirmResetAccess()}
        onCancel={() => setShowConfirmReset(null)} />

      <ListenerActivityModal show={!!showListenerActivity} onHide={() => setShowListenerActivity(null)} product={product} listener={showListenerActivity} />

      <BulkUploadListenersModal
        show={showBulkModal}
        onHide={() => setShowBulkModal(false)}
        products={products}
        user={user}
        productId={product._id}
        callbackBulkUploadListeners={loadData}
        doNotSendAccess={product.draft == 1}
      />

      {csvData !== null && <CSVDownload data={csvData} headers={csvHeaders} filename="listeners.csv" />}
    </>
  );
}

export default injectIntl(connect(
  state => ({
    product: state.product.product || {},
    products: state.product.products,
    episodes: (state.product.product || {}).episodes || [],
    listeners: (state.listener || {}).listeners || [],
    user: state.auth.user
  }),
  dispatch => ({
    ...actions.product,
    ...actions.listener,
    dispatch,
  }),
)(Listeners));
