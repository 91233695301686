import React from 'react';
import { useTranslation } from 'react-i18next';
import { displayPrice, numberFormat } from '../../../../helpers';

export function Price({ price, label, customAmount, discountPrice = null }) {
  const { t } = useTranslation();

  const isPayWhatYouWant = price?.type === 'pay-what-you-want';

  price = displayPrice(price, t);

  if(!price)
    return <></>;

  const amount = isPayWhatYouWant
    ? customAmount
    : price.value;

  return (
    <div className='price'>
      {label && (
        <>
          <label className='fs-7'>{t('Total payment')}</label>
          <br />
        </>
      )}

      {price.free && <strong>{t("Free")}</strong>}

      {label && price.payWhatYouWant && customAmount && (
        <strong>
          {price.currency} {amount}
        </strong>
      )}

      {!price.free && !price.payWhatYouWant && (
        <>
          {discountPrice !== null ? (
            <>
              <s className='fw-normal'>
                {price.currency}
                {amount}
              </s>
              {discountPrice > 0 ? (
                <strong className='pl-3 text-primary'>
                  {price.currency}
                  {numberFormat(discountPrice)}
                </strong>
              ) : (
                <strong className='pl-3 text-primary'>
                  {t("Free")}
                </strong>
              )}
            </>
          ) : (
            <strong>
              {price.currency}
              {amount}
            </strong>
          )}

          {price.periodLong && (
            <>
              <br />
              <small className='text-muted'>{price.periodLong}</small>
            </>
          )}
        </>
      )}
    </div>
  );
}
