import React from 'react';
import './paymentTestModeBanner.scss';

export function PaymentTestModeBanner() {
    return (
      <div className="payment-test-mode-banner">
        <div className="container-regular payment-test-mode-banner-container">
          <div>Test Mode</div>
          <div>You’re using test data. No real transactions will be processed.</div>
        </div>
      </div>
    )
}
