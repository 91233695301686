import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import { PaymentForm, PaymentFormElements } from './PaymentForm';
import { PurchaseSummary } from './PurchaseSummary';

import Lottie from "react-lottie";
import animationData from "../../../../layout/lottie/success.json";

export function CompletePaymentFlow({
  product,
  author,
  stripeObject,
  onSuccess,
  onFailure,
  isModal = true,
  showForm = false,
  onHideForm = () => { },
  redirectOnSuccess = false,
}) {
  const { t } = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [installUrl, setInstallUrl] = useState(null);
  const [installEmail, setInstallEmail] = useState(null);
  const [purchasedPrice, setPurchasedPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [failedReason, setFailedReason] = useState(null);

  const handlePaymentSuccess = (res) => {
    if(onSuccess) onSuccess(res);

    if(redirectOnSuccess && product.thankYouUrl) {
      window.location.href = product.thankYouUrl;
    } else {
      onHideForm();

      setInstallUrl(res.installUrl);
      setInstallEmail(res.email);
      setPurchasedPrice(res.price);
      setDiscountPrice(res.discountPrice);

      setShowSuccessModal(true);
    }
  };

  const handlePaymentFailure = (reason) => {
    if(onFailure) onFailure(reason);

    if(isModal) onHideForm();

    setFailedReason(reason);

    setShowFailedModal(true);
  };

  const handleRetry = () => {
    setShowFailedModal(false);
    if(isModal) onHideForm(true);
  };

  return (
    <>
      {isModal ? (
        <Modal
          show={showForm}
          onHide={onHideForm}
          size="md"
          centered
          className="new-modals payment-modal modal-fullscreen-sm-down"
        >
          {stripeObject
            ? (
              <Elements stripe={stripeObject}>
                <PaymentFormElements
                  product={product}
                  onHide={onHideForm}
                  author={author}
                  isModal
                  onSuccess={handlePaymentSuccess}
                  onFailure={handlePaymentFailure}
                />
              </Elements>
            )
            : (
              <PaymentForm
                product={product}
                author={author}
                onHide={onHideForm}
                isModal
                onSuccess={handlePaymentSuccess}
                onFailure={handlePaymentFailure}
              />
            )}
        </Modal>
      ) : (
        <div className="payment-modal bg-background">
          {stripeObject
            ? (
              <Elements stripe={stripeObject}>
                <PaymentFormElements
                  product={product}
                  author={author}
                  isModal={false}
                  onSuccess={handlePaymentSuccess}
                  onFailure={handlePaymentFailure}
                />
              </Elements>
            )
            : (
              <PaymentForm
                product={product}
                author={author}
                isModal={false}
                onSuccess={handlePaymentSuccess}
                onFailure={handlePaymentFailure}
              />
            )}
        </div>
      )}

      {/* SUCCESS MODAL */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        size='lg'
        centered
        className='new-modals success-modal modal-fullscreen-sm-down'
      >
        <Modal.Body className='text-center'>
          <button
            type='button'
            className='close'
            onClick={() => setShowSuccessModal(false)}
          >
            <SVG
              src={toAbsoluteUrl('/media/def-image/close.svg')}
              className='svg-icon'
            />
          </button>
          <div>
           
             <div className="empty-lottie">
                  <Lottie options={{
                    animationData,
                    loop: false,
                    autoplay: true
                  }} height={240} width={240} />
                </div>
                  
            <h1>{t('Thank you!')}</h1>
            <p>
              {t(
                "You've successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.",
                {
                  productName: product.name,
                  author: author.firstName
                    ? author.firstName +
                    (author.lastName ? ' ' + author.lastName : '')
                    : author.email,
                  installEmail,
                }
              )}
            </p>
            {!isMobile && installUrl && (
              <>
                <p>
                  <strong>
                    {t(
                      "Point your phone's camera at the code below to access the private podcast."
                    )}
                  </strong>
                </p>
                <QRCode value={installUrl} size='76' />
              </>
            )}
            <div className='purchase'>
              <h2>{t('Purchased item')}</h2>
              <PurchaseSummary
                product={product}
                author={author}
                className='round'
                purchasedPrice={purchasedPrice}
                discountPrice={discountPrice}
              />
            </div>
            {installUrl && (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => (window.location.href = installUrl)}
              >
                {t('Access Private Feed Now')}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* FAILURE MODAL */}
      <Modal
        show={showFailedModal}
        onHide={() => setShowFailedModal(false)}
        size='lg'
        centered
        className='new-modals success-modal modal-fullscreen-sm-down'
      >
        <Modal.Body className='text-center'>
          <button
            type='button'
            className='close'
            onClick={() => setShowFailedModal(false)}
          >
            <SVG
              src={toAbsoluteUrl('/media/def-image/close.svg')}
              className='svg-icon'
            />
          </button>
          <div>
            <img src={toAbsoluteUrl('/media/def-image/purchase-failed.svg')} />
            <h1>{t('Oh no, something went wrong')}</h1>
            <p>
              {t(
                failedReason
                  ? failedReason
                  : 'Check your details and try one more time.'
              )}
            </p>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleRetry}
            >
              {t('Try one more time')}
            </button>
            <br />
            <button
              type='button'
              className='btn btn-transparent text-muted'
              onClick={() => setShowFailedModal(false)}
            >
              {t('Cancel')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CompletePaymentFlow;
