import React, { useEffect } from "react";
import { Redirect, Switch, Route, useLocation, withRouter } from "react-router-dom";
import ProductsList from "./pages/products/ProductsList";
import { useDispatch, connect, useSelector, shallowEqual } from 'react-redux';
import Listeners from "./pages/products/listeners/Listeners";
import ProductSetting from "./pages/products/setting/ProductSetting";
import Episodes from "./pages/products/episodes/Episodes";
import { Error } from "./modules/CustomPage/Error";
import actions from "../redux/actions";
import api from "../redux/api";
import { ErrorBoundary } from "./helpers";
import SalesPageSettings from "./pages/products/sales-page/salesPageSettings/SalesPageSettings";
import { injectIntl } from "react-intl";
import Layout from "./layout/Layout";
import ManageSMSCodes from "./pages/sms/ManageSMSCodes";
import SMSCodeEdit from "./pages/sms/SMSCodeEdit";
import Dashboard from "./pages/home/Dashboard";
import CreateProduct from "./pages/products/create-product/CreateProduct";
import EpisodeEdit from "./pages/products/episodes/EpisodeEdit";
import DeleteProduct from "./pages/products/delete-product/DeleteProduct";
import DeleteProductConfirm from "./pages/products/delete-product/DeleteProductConfirm";
import BulkUpload from "./pages/products/episodes/generic/BulkUpload";
import ProductOverview from "./pages/products/ProductOverview";
import OrganizationSettings from "./pages/account/UserProfile/OrganizationSettings";
import BillingSettings from "./pages/account/UserProfile/BillingSettings";
import ProfilePageSettings from "./pages/account/UserProfile/ProfilePageSettings";
import Partnership from "./pages/account/UserProfile/Partnership";
import Password from "./pages/account/UserProfile/Password";
import NotificationsSettings from "./pages/account/UserProfile/NotificationsSettings";
import Integrations from "./pages/account/UserProfile/Integrations";
import Automations from "./pages/automations/Automations";
import EditAutomation from "./pages/automations/EditAutomation";
import Inbox from "./pages/sms/Inbox";
import Contacts from "./pages/contacts/Contacts";
import Campaigns from "./pages/campaigns/Campaigns";
import EditCampaign from "./pages/campaigns/EditCampaign";
import DomainsSettings from "./pages/account/UserProfile/DomainsSettings";
import AddDomainSettings from "./pages/account/UserProfile/AddDomainSettings";
import DomainSettings from "./pages/account/UserProfile/DomainSettings";
import Tags from "./pages/contacts/Tags";
import AIChat from "./layout/ai/AIChat";
import Categories from "./pages/products/Categories";
import EmailAccounts from "./pages/account/UserProfile/EmailAccounts";
import DeleteAccount from "./pages/account/UserProfile/DeleteAccount";
import PhoneNumbers from "./pages/account/UserProfile/PhoneNumbers";
import Developer from "./pages/account/UserProfile/Developer";
import Whatsapp from "./pages/account/UserProfile/Whatsapp";
import DesktopOnly from "./helpers/DesktopOnly";

let currentProductId = null,
  currentUserId = null;

const Routes = ({ user }) => {
  let location = useLocation();

  return (
    <Layout>
      <Switch>
        <Redirect exact from="/" to={{ pathname: "/home", hash: location.hash, search: location.search }} />

        <Route path="/create-product" component={CreateProduct} />
        <Route path="/create-product/resume/:id" component={CreateProduct} />
        <Route path="/products/youtube-import" component={ProductsList} />
        <Route path="/products/:id" exact component={ProductOverview} />
        <Route path="/products/:id/episodes/bulk-upload" component={BulkUpload} />
        <Route exact path="/products/:id/episodes/:episodeId" component={EpisodeEdit} />
        <Route path="/products/:id/episodes/create-single" component={EpisodeEdit} />
        <Route exact path="/delete-product/:id" component={DeleteProduct} />
        <Route exact path="/delete-product/:id/confirm" component={DeleteProductConfirm} />
        <Route path="/home" component={Dashboard} />
        <Route path="/sms/:id/edit" render={props => <SMSCodeEdit key={props.match.params.id} {...props} />} />
        <Route path="/sms/edit" render={props => <SMSCodeEdit key={0} {...props} />} />
        <Route path="/sms" component={ManageSMSCodes} />
        <Route path="/products/:id/episodes" render={props => <Episodes key={props.match.params.id} {...props} />} />
        <Route path="/products/:id/listeners" component={Listeners} />
        <Route path="/products/:id/settings" component={ProductSetting} />
        <Route path="/products/:id/sales-page" component={SalesPageSettings} />
        <Route path="/products" component={ProductsList} />

        <Route path="/settings/organization" component={OrganizationSettings} />
        <Route path="/settings/billing" component={BillingSettings} />
        <Route path="/settings/password" component={Password} />

        <Route path="/profile-page" component={ProfilePageSettings} />
        <Route path="/delete-account" component={DeleteAccount} />

        <Route path="/user/partnership" component={Partnership} />
        <Route path="/user/notifications" component={NotificationsSettings} />

        <Route path="/user/domains/create" component={AddDomainSettings} />
        <Route path="/user/domains/:id" component={DomainSettings} />
        <Route path="/user/domains" component={DomainsSettings} />

        <Route path="/user/email-accounts" component={EmailAccounts} />
        <Route path="/user/developer" component={Developer} />
        <Route path="/user/phone-numbers" component={PhoneNumbers} />
        <Route path="/user/whatsapp" component={Whatsapp} />
        <Route path="/user/integrations" component={Integrations} />

        <Route path="/automations/:id/edit" render={props => <EditAutomation key={props.match.params.id} {...props} />} />
        <Route path="/automations/edit" render={props => <EditAutomation key={0} {...props} />} />
        <Route path="/automations" component={Automations} />

        <Route path="/inbox" component={Inbox} />
        <Route path="/inbox/*" component={Inbox} />

        <Route path="/contacts" component={Contacts} />
        <Route path="/contacts/*" component={Contacts} />

        <Route path="/tags" component={Tags} />
        <Route path="/tags/*" component={Tags} />

        <Route path="/categories" component={Categories} />
        <Route path="/categories/*" component={Categories} />

        <Route path="/campaigns/:id/edit" render={props => <EditCampaign key={props.match.params.id} {...props} />} />
        <Route path="/campaigns/:id/duplicate" render={props => <EditCampaign key={props.match.params.id} {...props} />} />
        <Route path="/campaigns/edit" render={props => <EditCampaign key={0} {...props} />} />
        <Route path="/campaigns/" component={Campaigns} />

        <Route path="/404" component={Error} />
        <Redirect to={"/404" + location.search} />
      </Switch>
      <AIChat />
    </Layout>
  );
};

function BasePage(props) {
  let dispatch = useDispatch(),
    selectedProduct = useSelector(state => state.product.product, shallowEqual),
    location = useLocation();

  //Update user object on page load
  useEffect(() => {
    api.auth.getUserByToken(true).then(res => {
      if(!res || !res.data)
        return;
      dispatch(props.fulfillUser(res.data));
    });
  }, []);

  let checkLocation = () => {
    let matches = location.pathname.match(/\/products\/([a-f0-9]+)/);
    if(!matches) {
      //props.dispatch(props.setProduct(null));
      return false;
    }

    if(currentProductId == matches[1])
      return true;

    api.product.getProduct(matches[1]).then(res => {
      if(!res) {
        window.location.href = "/products";
        return;
      }
      currentProductId = res._id;
      props.dispatch(props.setProduct(res));
    });

    return true;
  };

  useEffect(() => {
    checkLocation();
  }, [location]);

  useEffect(() => {
    if(!props.auth.user || currentUserId == props.auth.user._id) return;
    currentUserId = props.auth.user._id;

    api.user.getPortalLink(props.auth.user.stripeCustomerId).then(res => {
      if(res)
        dispatch(actions.user.setPortalLink(res));
    });

    dispatch(actions.phoneNumber.setPhoneNumber(props.auth.user.phoneNumbers.length
      ? props.auth.user.phoneNumbers[0]
      : null
    ));

    //api.user.getDownloadCount(props.auth.user._id).then(res => {
    //  if(res)
    //    dispatch(actions.user.setDownloadCount(res));
    //});

    api.product.getProducts(true).then(res => {
      if(res) {
        window.localStorage.setItem("productsCount", (res && Array.isArray(res) && res.length) || 0);
        dispatch(actions.product.setProducts(res));
        if(!checkLocation())
          props.dispatch(props.setProduct(res[0]));
      }
    });

    if(window.stonlyTrack)
      window.stonlyTrack("identify", props.auth.user._id);
  }, [props.auth.user]);

  return process.env.NODE_ENV === 'production' ? <ErrorBoundary><Routes user={props.auth.user} /></ErrorBoundary> : <Routes user={props.auth.user} />;
}

export default injectIntl(
  connect(
    (state) => ({
      auth: state.auth
    }),
    (dispatch) => ({
      ...actions.auth,
      ...actions.product,
      dispatch
    })
  )(withRouter(BasePage))
);