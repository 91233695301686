/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { UpgradeEvents } from "../../../../../app/pages/modals/UpgradeModal";
import UserPicture from "../../../../../app/layout/components/UserPicture";
import { FivePackEvents } from "../../../../../app/pages/modals/FivePackModal";

//TODO Clean up this markup

export function UserProfileDropdown() {
  const auth = useSelector((state) => state.auth),
    user = auth.user?.owner || auth.user,
    name = (user.superUser
      ? user.companyName
      : user.firstName + " " + user.lastName).trim();

  return (
    <>
      <Dropdown drop="down" alignRight className="dropdown-user-profile">
        <Dropdown.Toggle as="a">
          <UserPicture user={user} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div className="d-flex align-items-center py-5 px-8 rounded-top">
            <div className="menu-content flex-grow-1 d-flex align-items-center">
              <UserPicture user={user} />
              <div className="d-flex flex-column">
                <div className="fw-bold d-flex align-items-center fs-5 mb-1">
                  <span className="user-name d-inline-block ellipsis">{name || <span className="text-muted">(No name)</span>}</span>
                  {user.noPlan ? <></> : <span className="green-badge ml-2">{user.planFeatures.title}</span>}
                </div>
                <div className="text-muted fs-7 user-email d-inline-block ellipsis">{user.email}</div>
              </div>
            </div>
          </div>
          <div className="separator separator-solid" />

          <div className="navi navi-spacer-x-0">
            <Link to="#" onClick={ev => {
              ev.preventDefault();
              document.body.click();
              UpgradeEvents.dispatchShow();
            }} className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Change Plans
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#" onClick={ev => {
              ev.preventDefault();
              document.body.click();
              FivePackEvents.dispatchShow();
            }} className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Additional Accounts
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/settings/organization" className="navi-item px-8 cursor-pointer no-plan-cant-click">
              <div className="navi-link">
                <div className="navi-text font-weight-normal">
                  {user.superUser
                    ? "Admin settings"
                    : "Organization"}
                </div>
              </div>
            </Link>

            <Link to="/settings/password" className="navi-item px-8 cursor-pointer no-plan-cant-click">
              <div className="navi-link">
                <div className="navi-text font-weight-normal">Email & Password</div>
              </div>
            </Link>

            <Link to="/settings/billing" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-text font-weight-normal">Billing</div>
              </div>
            </Link>

            <div className="separator separator-solid mt-3" />

            <a href="https://hiro.fm/help-center" className="navi-item px-8 cursor-pointer" target="_blank">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Help Center
                  </div>
                </div>
              </div>
            </a>

            <a href="https://hiro.fm/tickets/" className="navi-item px-8 cursor-pointer" target="_blank">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Support
                  </div>
                </div>
              </div>
            </a>

            <a href="https://www.facebook.com/groups/superhiros" className="navi-item px-8 cursor-pointer" target="_blank">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Join Facebook Group
                  </div>
                </div>
              </div>
            </a>

            <a href="https://partner.hiro.fm" className="navi-item px-8 cursor-pointer" target="_blank">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Become An Affiliate
                  </div>
                </div>
              </div>
            </a>

            <div className="separator separator-solid mt-3" />

            <a href="https://hiro.fm/terms" className="navi-item px-8 cursor-pointer" target="_blank">
              <div className="navi-link">
                <div className="navi-text">
                  <div className="font-weight-normal cursor-pointer">
                    Terms Of Service
                  </div>
                </div>
              </div>
            </a>

            <div className="navi-footer px-8 pb-5 pt-0">
              <a href="https://hiro.fm/privacy" className="navi-item cursor-pointer" target="_blank">
                <div className="navi-link">
                  <div className="navi-text">
                    <div className="font-weight-normal cursor-pointer">
                      Privacy
                    </div>
                  </div>
                </div>
              </a>

              <Link
                to="/logout"
                className="btn btn-light-primary font-weight-normal"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
