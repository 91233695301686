import { useFetch } from "../../app/helpers";


export default {
  getPlan: (hideError, id) => useFetch.get(`plan/${id}`, {}, hideError),
  setPlan: (id, hideError) => useFetch.put(`stripeplan/${id}`, {}, hideError),
  editCurrentUser: (data, hideError) => useFetch.put('update-current-instructor/', data, hideError, hideError),
  setResetPassword: data => useFetch.post('public/resetpassword/', data),
  setChangePassword: (data, hideError) => useFetch.put('change-password/', data, hideError),
  getDownloadCount: (id) => useFetch.get(`/public/downloadcount/${id}`, {}),
  getPortalLink: (id) => useFetch.get(`/stripe/portal/${id}`, {}),
  makeAnnual: data => useFetch.post("/stripe/subscription/make-annual", data),
  disconnectStripe: () => useFetch.post("/stripe/disconnect-account"),
  getPlans: () => useFetch.get("plans"),
  dismissPricingTable: () => useFetch.post("/dismiss-pricing-table"),
  dismissPushNotificationsPopup: () => useFetch.post("/dismiss-notifications"),
  dismissWelcomePopup: () => useFetch.post("/dismiss-welcome"),
  enableNotifications: data => useFetch.post("/enable-notifications", data),
  disableNotifications: id => useFetch.post("/disable-notifications", { id }),
  getNotifications: () => useFetch.get("/notifications", null, true),
  getNotificationsCount: () => useFetch.get("/notifications-count", null, true),
  seenNotifications: type => useFetch.post("/notifications", { seen: type }, true),
  configureNotifications: params => useFetch.post("/configure-notifications", params),
  submitEventsForm: () => useFetch.post("/submit-events-form"),
  notificationClick: id => useFetch.post("/notification-click", { id }, true),
  getDashboard: () => useFetch.get("/dashboard"),
  getListenersMapData: () => useFetch.get("/listeners-map-data"),
  onboardingStep: (step, data = {}) => useFetch.post("/onboarding/" + step, data, true, true),
  createPaymentIntentForCredits: amount => useFetch.post("/stripe/payment-intent/credits", { amount }, true, true),
  buyCredits: amount => useFetch.post("/stripe/buy-credits", { amount }, true, true),
  listDomains: () => useFetch.get("/domains", null, true, true),
  addDomain: domain => useFetch.post("/domains", { domain }, true, true),
  removeDomain: id => useFetch.delete("/domains/" + id, null, true, true),
  getDomainDetails: id => useFetch.get("/domains/" + id, null, true, true),
  verifyDomain: id => useFetch.put("/domains/" + id + "/verify", null, true, true),
  getRoutes: host => useFetch.get("/user-routes?host=" + host, null, true, true),
  downgrade: () => useFetch.post("/downgrade", null, true, true),
  getWordpressOtpLink: () => useFetch.get("/wordpress-otp", null, true, true),

  //User Twilio & Telnyx
  getUserTwilioData: () => useFetch.get("/user-twilio", null, true, true),
  setUserTwilio: data => useFetch.post("/user-twilio", { data }, true, true),
  disconnectUserTwilio: () => useFetch.delete("/user-twilio", null, true, true),
  setUserTwilioPhoneNumber: numberSid => useFetch.put("/user-twilio", { data: { numberSid } }, true, true),
  getUserTelnyxData: () => useFetch.get("/user-telnyx", null, true, true),
  setUserTelnyx: data => useFetch.post("/user-telnyx", { data }, true, true),
  disconnectUserTelnyx: () => useFetch.delete("/user-telnyx", null, true, true),
  setUserTelnyxPhoneNumber: numberId => useFetch.put("/user-telnyx", { data: { numberId } }, true, true),

  //Email addresses
  testEmailAddress: data => useFetch.post("/test-email-address", data, true, true),
  addEmailAddress: data => useFetch.post("/email-addresses", data, true, true),
  updateEmailAddress: (id, data) => useFetch.put("/email-addresses/" + id, data, true, true),
  deleteEmailAddress: id => useFetch.delete("/email-addresses/" + id, null, true, true),
  getEmailAddressDetails: (id, type) => useFetch.get("/email-addresses/" + id + "/" + type, null, true, true),
  validateGmailConnection: accessToken => useFetch.get("/connect-gmail", { accessToken }, true, true),

  //Subaccounts
  createSubaccount: data => useFetch.post("/create-subaccount", data, true, true),
  setSubaccount: subAccountId => useFetch.put("/set-subaccount", { subAccountId }, true, true),
  updateSubaccountStatus: enabled => useFetch.post("/update-subaccount-statuts", { enabled }, true, true),
  deleteSubaccount: () => useFetch.post("/delete-subaccount", null, true, true),

  //Trackdesk
  getPartnership: () => useFetch.get("/partnership", null, true, true),
  updatePartnership: data => useFetch.put("/partnership", data, true, true),

  //Public API tokens
  getPublicApiTokens: () => useFetch.get("/api-tokens", null, true, true),
  createPublicApiToken: data => useFetch.post("/api-tokens", data, true, true),
  updatePublicApiToken: (tokenId, data) => useFetch.put("/api-tokens/" + tokenId, data, true, true),
  removePublicApiToken: tokenId => useFetch.delete("/api-tokens/" + tokenId, null, true, true),

  //Share codes
  validateShareCode: code => useFetch.get("/validate-share-code?code=" + encodeURIComponent(code), null, true, true),
  redeemShareCode: code => useFetch.post("/redeem-share-code", { code }, true, true),
  createShareCode: data => useFetch.post("/share-codes", data, true, true),
  updateShareCode: (code, data) => useFetch.put("/share-codes/" + code, data, true, true),
  deleteShareCode: code => useFetch.delete("/share-codes/" + code, null, true, true),
  getShareCodeDetails: code => useFetch.get("/share-codes/" + code, null, true, true),

  //Whatsapp
  setUserTelnyxPhoneNumberForWhatsapp: numberId => useFetch.put("/user-telnyx", { data: { numberId, whatsapp: true } }, true, true),
  getWhatsappConfirmationCode: () => useFetch.get("/whatsapp-confirmation-code", null, true, true),
  setupWhatsappPhoneNumber: loginData => useFetch.post("/whatsapp-phone-number", loginData, true, true),
  disconnectWhatsappPhoneNumber: () => useFetch.delete("/whatsapp-phone-number", null, true, true),

  //Payment settings
  setPaymentTestMode: enabled => useFetch.post('/test-mode/set', { enabled }),
};
