import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { withRouter, useHistory, Link } from "react-router-dom";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";
import UpgradeLink from "../generic/UpgradeLink";
import { toastMessage } from "../../helpers";
import { updateCurrentUser } from "../../helpers/utils";
import NewProductDropdown from "../../layout/dropdowns/NewProductDropdown";

const mapboxgl = window.mapboxgl;
if(mapboxgl)
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN;

function Dashboard({ user, products, dispatch }) {
    let [loading, setLoading] = useState(true),
        [data, setData] = useState(null),
        [mapLoading, setMapLoading] = useState(true),
        [checklistState, setChecklistState] = useState({}),
        [mapError, setMapError] = useState(false),
        history = useHistory(),
        mapbox = useRef();

    async function loadData() {
        let res = await api.user.getDashboard();
        setLoading(false);
        if(res.success)
            setData(res.data);
    }

    async function loadMapData() {
        if(!mapbox.current)
            return;

        let res = await api.user.getListenersMapData();
        setMapLoading(false);
        if(!res.success)
            return;

        try {
            mapbox.current.removeLayer("listener-activity-heatmap-layer");
            mapbox.current.removeLayer("listener-activity-points-layer");
            mapbox.current.removeSource("listener-activity");
        } catch(err) { }

        try {
            mapbox.current.addSource("listener-activity", {
                type: "geojson",
                data: res.data
            });

            mapbox.current.addLayer({
                id: "listener-activity-heatmap-layer",
                type: "heatmap",
                source: "listener-activity",
                paint: {
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0,
                        "rgba(56, 156, 255, 0)",
                        1,
                        "rgba(56, 156, 255, .9)"
                    ]
                },
                maxzoom: 4
            });

            mapbox.current.addLayer({
                id: "listener-activity-points-layer",
                type: "circle",
                source: "listener-activity",
                paint: {
                    "circle-color": "rgb(56, 156, 255)"
                },
                minzoom: 3
            });
        } catch {
            setMapError(true);
        }
    }

    function listenerRowOnClick(item) {
        history.push("/products/" + item.productId + "/listeners?search=" + encodeURIComponent(item.name));
    }

    function episodeRowOnClick(item) {
        history.push("/products/" + item.productId + "/episodes");
    }

    async function hideCard(prop) {
        let data = {};
        data[prop] = true;

        await api.user.editCurrentUser({
            action: "updateOnboardingCards",
            user: {},
            data
        });

        await updateCurrentUser(dispatch);
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if(mapboxgl && products && products.length) {
            try {
                mapbox.current = new mapboxgl.Map({
                    container: "mapbox",
                    style: "mapbox://styles/mapbox/light-v11",
                    projection: "mercator",
                    zoom: .5,
                    center: [39, 34]
                });

                mapbox.current.on("load", () => {
                    mapbox.current.addControl(new mapboxgl.NavigationControl());
                    loadMapData();
                });
            } catch {
                setMapError(true);
            }
        }
    }, [products]);

    useEffect(() => {
        loadData();
        loadMapData();
    }, [user._id]);

    useEffect(() => {
        let state = {
            email: "pending",
            phone: "pending",
            whatsapp: "pending",
            domain: "pending",
            stripe: "pending"
        };

        if(user) {
            if(user.emailAddresses && user.emailAddresses.length)
                state.email = user.emailAddresses.find(a => a.status == "failed")
                    ? "error"
                    : "done";

            state.phone = user.phoneNumbers && user.phoneNumbers.find(p => p.kind == "sms")
                ? "done"
                : "pending";

            if(user.phoneNumbers && user.phoneNumbers.length) {
                let number = user.phoneNumbers.find(p => p.kind == "whatsapp");
                if(number)
                    state.whatsapp = number.pending
                        ? "pending-setup"
                        : "done";
            }

            if(user.domains && user.domains.length)
                state.domain = user.domains.find(d => d.status == "pending")
                    ? "pending-setup"
                    : "done";

            state.stripe = user.stripeConnected
                ? "done"
                : "pending";
        }

        setChecklistState(state);
    }, [user]);

    return (
        <div className="dashboard-v3">
            <h2 className="hi">
                👋 Hey {user.firstName || user.email.substring(0, user.email.indexOf("@"))}! <span className="text-dark">Welcome back</span>
            </h2>
  
            {user && !user.onboarding.hideGettingStartedCard && (
                <div className="card getting-started-card mb">
                    <div className="card-body">
                        <button type="button" className="btn hide-card" onClick={() => hideCard("hideGettingStartedCard")}>
                            <SVG src="/media/def-image/close.svg" />
                        </button>
                        <h2 className="mb-2">Getting started</h2>
                        <p className="mb-5">Complete these 5 steps to get your account setup.</p>

                        <div className="checklist">
                            <div className={"checklist-item state-" + checklistState.email}>
                                <span className="number">1</span>
                                <span className="check">
                                    <SVG src="/media/def-image/icons/check-2.svg" />
                                </span>
                                <div className="text">
                                    Add email
                                    {checklistState.email == "error" && <span className="badge red-badge ml-4">Failed</span>}
                                </div>
                                <div className="options">
                                    {checklistState.email == "done"
                                        ? <span className="text-muted">Completed</span>
                                        : <Link to="/user/email-accounts">Edit <SVG src="/media/def-image/icons/angle-right-blue.svg" /></Link>}
                                </div>
                            </div>

                            <div className={"checklist-item state-" + checklistState.phone}>
                                <span className="number">2</span>
                                <span className="check">
                                    <SVG src="/media/def-image/icons/check-2.svg" />
                                </span>
                                <div className="text">
                                    Add phone <small>(Optional)</small>
                                </div>
                                <div className="options">
                                    {checklistState.phone == "done"
                                        ? <span className="text-muted">Completed</span>
                                        : <Link to="/user/phone-numbers">Edit <SVG src="/media/def-image/icons/angle-right-blue.svg" /></Link>}
                                </div>
                            </div>

                            <div className={"checklist-item state-" + checklistState.whatsapp}>
                                <span className="number">3</span>
                                <span className="check">
                                    <SVG src="/media/def-image/icons/check-2.svg" />
                                </span>
                                <div className="text">
                                    Add WhatsApp <small>(Optional)</small>
                                    {checklistState.whatsapp == "pending-setup" && <span className="badge yellow-badge ml-4">Pending setup</span>}
                                </div>
                                <div className="options">
                                    {checklistState.whatsapp == "done"
                                        ? <span className="text-muted">Completed</span>
                                        : <Link to="/user/whatsapp">Edit <SVG src="/media/def-image/icons/angle-right-blue.svg" /></Link>}
                                </div>
                            </div>

                            <div className={"checklist-item state-" + checklistState.domain}>
                                <span className="number">4</span>
                                <span className="check">
                                    <SVG src="/media/def-image/icons/check-2.svg" />
                                </span>
                                <div className="text">
                                    Add domain name <small>(Optional)</small>
                                    {checklistState.domain == "pending-setup" && <span className="badge yellow-badge ml-4">Pending validation</span>}
                                </div>
                                <div className="options">
                                    {checklistState.domain == "done"
                                        ? <span className="text-muted">Completed</span>
                                        : <Link to="/user/domains">Edit <SVG src="/media/def-image/icons/angle-right-blue.svg" /></Link>}
                                </div>
                            </div>

                            <div className={"checklist-item state-" + checklistState.stripe}>
                                <span className="number">5</span>
                                <span className="check">
                                    <SVG src="/media/def-image/icons/check-2.svg" />
                                </span>
                                <div className="text">
                                    Connect Stripe account <small>(Optional)</small>
                                </div>
                                <div className="options">
                                    {checklistState.stripe == "done"
                                        ? <span className="text-muted">Completed</span>
                                        : <Link to="/user/integrations">Edit <SVG src="/media/def-image/icons/angle-right-blue.svg" /></Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {user && !user.onboarding.hideProfileCard && (
                <div className="card getting-started-card mb card-bg-right" style={{ backgroundImage: "url('/media/def-image/ob-profile-bg.png')" }}>
                    <div className="card-body">
                        <button type="button" className="btn hide-card" onClick={() => hideCard("hideProfileCard")}>
                            <SVG src="/media/def-image/close.svg" />
                        </button>

                        <h2 className="mb-2">Create an amazing profile</h2>
                        <p className="mb-8">Then share your profile page on social media, emails<br />signatures, your website and more!</p>

                        <div className="d-flex gap">
                            <Link to="/profile-page" className="btn btn-primary">Setup profile page</Link>
                            <a href="https://hiro.fm/kb/how-to-configure-profile-page/" target="_blank" className="btn btn-secondary">Show me how</a>
                        </div>
                    </div>
                </div>
            )}

            {user && products && !products.length && !user.onboarding.hideFirstProductCard && (
                <div className="card getting-started-card mb card-bg-right" style={{ backgroundImage: "url('/media/def-image/onboarding-audioshow-bg.png')" }}>
                    <div className="card-body">
                        <button type="button" className="btn hide-card" onClick={() => hideCard("hideFirstProductCard")}>
                            <SVG src="/media/def-image/close.svg" />
                        </button>

                        <h2 className="mb-2">Make your first audio show</h2>
                        <p className="mb-8">After you create your show, you can set up<br />automations, keyword opt-in, and the sales page.</p>

                        <div className="d-flex gap">
                            <NewProductDropdown className="inline" toggleClassName="btn-primary">Try it out</NewProductDropdown>
                            <a href="https://hiro.fm/kb/how-to-create-a-show/" target="_blank" className="btn btn-secondary">Show me how</a>
                        </div>
                    </div>
                </div>
            )}

            {loading || !data
                ? <div className="loading-block spinner spinner-lg mb-15" />
                : (
                    <>
                        <div className="row wide-gap stats">
                            <div className="col-lg-6">
                                <div className="card latest-listeners">
                                    <div className="card-body">
                                        <h2>Latest played shows</h2>
                                        {data.latestListeners.length
                                            ? (
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            {data.latestListeners.map((item, i) => (
                                                                <tr key={i} onClick={ev => listenerRowOnClick(item)}>
                                                                    <td width="100%" className="fw-semibold text-wrap">
                                                                        {item.name}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <span className="gray-badge">
                                                                            {moment(item.date).locale("en").fromNow()}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <img src={item.thumbnail} />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                            : (
                                                <div className="empty">
                                                    <div>
                                                        <SVG src={toAbsoluteUrl("/media/def-image/icons/stats.svg")} /><br />
                                                        You don’t have any stats to report yet.
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="card top-episodes">
                                    <div className="card-body">
                                        <h2 className="mb-3">Top episodes</h2>
                                        
                                        {data.topEpisodes.length
                                            ? (
                                                <div className="table-responsive">
                                                    <table className="table table-head-custom">
                                                        <tbody>
                                                            {data.topEpisodes.map((item, i) => (
                                                                <tr key={i} onClick={ev => episodeRowOnClick(item)}>
                                                                    <td>
                                                                        <img src={item.thumbnail} />
                                                                    </td>
                                                                    <td width="100%" className="text-wrap">
                                                                        {item.title}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <span className="gray-badge">{item.count}</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                            : (
                                                <div className="empty">
                                                    <div>
                                                        <SVG src={toAbsoluteUrl("/media/def-image/icons/stats.svg")} /><br />
                                                        You don’t have any stats to report yet.
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}

            <div className="row wide-gap support-cards">
                <div className="col-lg-4">
                    <div className="support-card having-trouble">
                        <SVG src="/media/def-image/dashboard-having-trouble-bg.svg" className="background" />
                        <h2>
                            <SVG src="/media/def-image/dashboard-having-trouble.svg" />
                            Having trouble?
                        </h2>
                        <p>Visit our help center to get support and answers to your questions.</p>
                        <a href="https://hiro.fm/help-center/" target="_blank">Visit support</a>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="support-card justify-content-start">
                        <SVG src="/media/def-image/dashboard-academy-bg.svg" className="background" />
                        <div className="usage-header">
                            <h2>
                                <SVG src="/media/def-image/icons/wa-stats.svg" />
                                Chat Usage
                            </h2>
                            <label className="col-total">Total</label>
                            <label className="col-remaining">Remaining</label>
                        </div>

                        {(loading || !data) && <div className="loading-block spinner spinner-lg mb-15" />}

                        {data?.usage.filter(item => item.isChat).map((item, i) => (
                            <div className="usage-row" key={i}>
                                <label className="col-label">{item.label}</label>
                                <label className="col-total">{item.current}</label>
                                <label className={"col-remaining " + (item.warning ? "warning" : "")}>{item.remaining}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="support-card justify-content-start">
                        <SVG src="/media/def-image/dashboard-checklist-bg.svg" className="background" />
                        <div className="usage-header">
                            <h2>
                                <SVG src="/media/def-image/icons/bars.svg" />
                                Usage
                            </h2>
                            <label className="col-total">Total</label>
                            <label className="col-remaining">Remaining</label>
                        </div>

                        {(loading || !data) && <div className="loading-block spinner spinner-lg mb-15" />}

                        {data?.usage.filter(item => item.isFeature).map((item, i) => (
                            <div className="usage-row" key={i}>
                                <label className="col-label">{item.label}</label>
                                <label className="col-total">{item.current}</label>
                                <label className={"col-remaining " + (item.warning ? "warning" : "")}>{item.remaining}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="card map">
                <div className="card-body">
                    <div className="d-md-flex align-items-center mb-6">
                        <h2 className="mb-md-0 flex-1">Listen activity by location</h2>
                        {!user.planFeatures.listenerLocationMap && <UpgradeLink user={user} />}
                    </div>
                    <div className={"mapbox-container " + (!user.planFeatures.listenerLocationMap ? "map-disabled" : "") + (mapLoading && !mapError ? "loading spinner spinner-center spinner-lg" : "")}>
                        {mapError
                            ? <div id="mapbox" className="mapbox-error">Refresh the page to load the map.</div>
                            : <div id="mapbox" />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            products: state.product.products,
            user: state.auth.user
        }),
        (dispatch) => ({
            ...actions.auth,
            dispatch
        })
    )(withRouter(Dashboard))
);
