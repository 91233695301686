import React, { useEffect, useState, useRef } from "react";
import api from "../../../../../redux/api";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { getInputClassName, displayFeedback, displayPrice } from "../../../../helpers";
import schemas from "../../../../schemas";
import { toastMessage } from "../../../../helpers";
import {
	CardElement,
	useStripe,
	useElements,
	PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { ReCaptcha } from "react-recaptcha-v3";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { Price } from "./Price";
import { PurchaseSummary } from "./PurchaseSummary";
import useAutosizeEmbed from "../../../../helpers/useAutosizeEmbed";
import './paymentForm.scss';

class UserError extends Error {
	constructor(message) {
		super(message);
		this.name = "UserError";
	}
}

let resolveRecaptchaPromise, lastListener;

/**
 * Component `PaymentForm`.
 * @param {boolean} [props.show] - Modal's `show`.
 * @param {function} [props.onHide] - Modal's `onHide`.
 * @param {function} [props.onSuccess]
 * @param {function} [props.onFailure]
 * @param {Object} [props.product]
 * @param {Object} [props.author]
 * @param {Object} [props.elements]
 * @param {Object} [props.stripe]
 * @param {boolean} [props.isModal=true] - Whether to render as modal or inline form
 */
export function PaymentForm({
	show = true,
	onHide = () => { },
	product,
	author,
	onSuccess,
	onFailure,
	elements,
	stripe,
	isModal = true
}) {
	const [loading, setLoading] = useState(false);
	const [selectedPrice, setSelectedPrice] = useState();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const formikRef = useRef();
	const [cardFeedback, setCardFeedback] = useState(null);
	const recaptchaRef = useRef(null);
	const { t } = useTranslation();
	const couponTimerRef = useRef();
	const couponInputRef = useRef();
	const [couponLoading, setCouponLoading] = useState();
	const [discountPrice, setDiscountPrice] = useState();
	const [couponId, setCouponId] = useState();

	let updateSize = useAutosizeEmbed();

	useEffect(() => {
		updateSize();
	}, [selectedPrice]);

	function clearCoupon() {
		clearTimeout(couponTimerRef.current);
		setCouponLoading(false);
		setDiscountPrice(null);
		setCouponId(null);
		if(couponInputRef.current) couponInputRef.current.value = "";
	}

	async function initPaymentRequest(
		stripe,
		product,
		selectedPrice,
		discountPrice
	) {
		if(selectedPrice.type == "free") return setPaymentRequest(null);

		let amount = discountPrice > 0 ? discountPrice : selectedPrice.amount;

		let pr = stripe.paymentRequest({
			country: "US",
			currency: selectedPrice.currency || "usd",
			total: {
				label: product.name + " " + selectedPrice.name,
				amount: Math.round(amount * 100)
			}
		});

		let result = await pr.canMakePayment();
		if(!result) return;

		pr.on("paymentmethod", ev => {
			setPaymentMethod(ev.paymentMethod);
			ev.complete("success");
			formikRef.current.handleSubmit();
		});

		setPaymentRequest(pr);
	}

	useEffect(() => {
		clearCoupon();
	}, [show, product, selectedPrice]);

	useEffect(() => {
		if(!product) return;

		if(product.prices.length == 1) setSelectedPrice(product.prices[0]);
	}, [product]);

	useEffect(() => {
		if(!selectedPrice || !stripe || !product) return;

		initPaymentRequest(stripe, product, selectedPrice, discountPrice);
	}, [product, stripe, selectedPrice, discountPrice]);

	const executeRecaptcha = async () => {
		//Disabled recaptcha due to cross-origin issues in pages with custom domains
		return;

		return new Promise((resolve, reject) => {
			resolveRecaptchaPromise = resolve;
			recaptchaRef.current.execute();
		});
	};

	const recaptchaCallback = token => {
		if(resolveRecaptchaPromise) resolveRecaptchaPromise(token);
	};

	const fail = reason => {
		setLoading(false);
		onHide();
		onFailure(typeof reason == "string" ? i18n.t(reason) : null);
		api.salesPage.failedPayment(product._id, lastListener);
		return null;
	};

	const success = res => {
		setLoading(false);
		onSuccess(res);
	};

	const grantAccess = async (values, payment = null) => {
		try {
			let res = await api.salesPage.createListener({
				values,
				payment,
				product: product._id,
				priceId: selectedPrice._id,
				couponId,
				recaptchaToken: await executeRecaptcha()
			});

			setLoading(false);

			if(!res || !res.success) return fail(values);

			success({
				...res,
				price: selectedPrice,
				discountPrice: discountPrice
			});
		} catch {
			fail();
		}
	};

	const processCustomer = async values => {
		let stripeCustomer = await api.salesPage.getCustomerByEmail(
			author._id,
			values.email,
			await executeRecaptcha()
		);
		if(!stripeCustomer.data || !stripeCustomer.data.id)
			stripeCustomer = await api.salesPage.createCustomer(
				author._id,
				values,
				await executeRecaptcha()
			);

		if(!stripeCustomer.data) return fail(stripeCustomer.message);

		return stripeCustomer;
	};

	async function processPayment(stripeCustomer, clientSecret, amount = 0) {
		if(!clientSecret) {
			let intent = await api.salesPage.createPaymentIntentForDirectChange(
				author._id,
				product._id,
				selectedPrice._id,
				couponId,
				stripeCustomer.data.id,
				amount,
				await executeRecaptcha()
			);

			if(!intent.data || !intent.data.client_secret)
				throw new UserError(intent.message);

			clientSecret = intent.data.client_secret;
		}

		let result = await stripe.confirmCardPayment(clientSecret, {
			payment_method: paymentMethod
				? paymentMethod.id
				: {
					card: elements.getElement(CardElement)
				}
		});

		if(result.error) {
			// if(result.error.type == "validation_error")
			//   setCardFeedback(i18n.t("Please verify the credit card details."));

			throw new UserError(result.error.message);
		}

		return result;
	}

	async function processOneTime(values) {
		try {
			let stripeCustomer = await processCustomer(values);
			if(!stripeCustomer) return;

			let result = await processPayment(stripeCustomer, null, values.amount);

			window.fbq?.("track", "Purchase", {
				currency: selectedPrice.currency || "usd",
				value:
					discountPrice !== null
						? discountPrice
						: selectedPrice.amount
			});

			window.gtag?.("event", "purchase", {
				currency: selectedPrice.currency || "usd",
				value:
					discountPrice !== null
						? discountPrice
						: selectedPrice.amount
			});

			grantAccess(values, result.paymentIntent.id);
		} catch(error) {
			fail(error.name == "UserError" && error.message);
		}
	}

	async function processSubscription(values) {
		try {
			let stripeCustomer = await processCustomer(values);
			if(!stripeCustomer) return;

			let subscription = await api.salesPage.createSubscription(
				stripeCustomer.data.id,
				author._id,
				null,
				product._id,
				selectedPrice._id,
				couponId,
				await executeRecaptcha()
			);

			if(!subscription.data) return fail(subscription.message);

			let paymentIntent = subscription.data.latest_invoice.payment_intent;

			await processPayment(stripeCustomer, paymentIntent.client_secret);

			window.fbq?.("track", "Purchase", {
				currency: selectedPrice.currency || "usd",
				value:
					discountPrice !== null
						? discountPrice
						: selectedPrice.amount
			});

			window.gtag?.("event", "purchase", {
				currency: selectedPrice.currency || "usd",
				value:
					discountPrice !== null
						? discountPrice
						: selectedPrice.amount
			});

			grantAccess(values, subscription.data.id);
		} catch(error) {
			fail(error.name == "UserError" && error.message);
		}
	}

	const sendForm = (values, formik) => {
		if(loading) return;
		setLoading(true);

		lastListener = values;

		if(
			selectedPrice.type == "free" ||
			(discountPrice != null && discountPrice <= 0)
		) {
			window.fbq?.("track", "Purchase", {
				currency: "USD",
				value: 0
			});

			window.gtag?.("event", "purchase", {
				currency: "USD",
				value: 0
			});

			return grantAccess(values);
		}

		if(selectedPrice.type == "one-time" || selectedPrice.type == "pay-what-you-want") return processOneTime(values);

		processSubscription(values);
	};

	async function couponOnInput() {
		clearTimeout(couponTimerRef.current);

		let value = couponInputRef.current.value.trim();

		if(!value) {
			setDiscountPrice(null);
			setCouponLoading(false);
			setCouponId(null);
			return;
		}

		couponTimerRef.current = setTimeout(async () => {
			setCouponLoading(true);

			let res = await api.product.validateCoupon(
				product._id,
				selectedPrice._id,
				value
			);

			setCouponLoading(false);

			if(!res || !res.success) {
				setDiscountPrice(null);
				setCouponId(null);
				toastMessage.error(
					(res && res.error) || "Unable to validate the coupon code."
				);
				return;
			}

			setDiscountPrice(res.discountPrice);
			setCouponId(res.couponId);
		}, 600);
	}

	const isFree = selectedPrice && selectedPrice.type == "free";
	const isPayWhatYouWant =
		selectedPrice && selectedPrice.type == "pay-what-you-want";

	useEffect(() => {
		if(formikRef.current && !formikRef.current.values.amount && selectedPrice && selectedPrice.type == "pay-what-you-want" && selectedPrice.suggestedAmount) {
			formikRef.current.setFieldValue("amount", selectedPrice.suggestedAmount);
		}
	}, [selectedPrice, formikRef.current]);

	return (
		<>
			<Formik
				initialValues={{
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
					coupon: "",
					amount: ""
				}}
				validationSchema={
					isFree
						? schemas.product.salesPage.getFreeAccess
						: isPayWhatYouWant
							? schemas.product.salesPage.customPriceBuy(
								selectedPrice.minAmount, displayPrice(selectedPrice, t).currency, t
							)
							: schemas.product.salesPage.buy
				}
				enableReinitialize
				validateOnBlur={false}
				validateOnChange={true}
				onSubmit={sendForm}
				innerRef={formikRef}
			>
				{formik => (
					<Form>
						<div className="modal-header border-0">
							{isModal && (
								<>
									<button
										type="button"
										className="close back"
										onClick={onHide}
									>
										<SVG
											src={toAbsoluteUrl(
												"/media/def-image/back-2.svg"
											)}
											className="svg-icon"
										/>
									</button>
								</>
							)}
							<h1 className="mb-0">
								{isFree ? t("Get Access") : t("Payment")}
							</h1>
							{isModal && (
								<button
									type="button"
									className="close"
									onClick={onHide}
								>
									<SVG
										src={toAbsoluteUrl(
											"/media/def-image/close.svg"
										)}
										className="svg-icon"
									/>
								</button>
							)}
						</div>
						<div className="modal-body">
							<PurchaseSummary
								product={product}
								author={author}
								selectedPrice={selectedPrice}
								setSelectedPrice={setSelectedPrice}
							/>

							{selectedPrice &&
								selectedPrice.type === "pay-what-you-want" && (
									<div className="row mb-6">
										<div className="form-group col-md-12">
											<label className="form-label">
												{t("Pay what you want")} <em>*</em>
											</label>

											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text">
														{displayPrice(selectedPrice, t).currency}
													</span>
												</div>
												<input
													type="number"
													className={"suggested-price-input " + getInputClassName(
														formik,
														"amount"
													)}
													{...formik.getFieldProps(
														"amount"
													)}
												/>
												{displayFeedback(formik, "amount")}
											</div>
										</div>
									</div>
								)}

							<div className="row mb-6">
								<div className="col-md-6 mb-6 mb-md-0">
									<div className="form-group">
										<label className="form-label">
											{t("First Name")} <em>*</em>
										</label>
										<input
											type="text"
											className={getInputClassName(
												formik,
												"firstName"
											)}
											{...formik.getFieldProps("firstName")}
										/>
										{displayFeedback(formik, "firstName")}
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label className="form-label">
											{t("Last Name")} <em>*</em>
										</label>
										<input
											type="text"
											className={getInputClassName(
												formik,
												"lastName"
											)}
											{...formik.getFieldProps("lastName")}
										/>
										{displayFeedback(formik, "lastName")}
									</div>
								</div>
							</div>
							<div className="form-group mb-6">
								<label className="form-label">
									{t("Email")} <em>*</em>
								</label>
								<input
									type="text"
									className={getInputClassName(formik, "email")}
									{...formik.getFieldProps("email")}
								/>
								{displayFeedback(formik, "email")}
							</div>
							<div className={"form-group" + (isFree ? " mb-0" : "")}>
								<label className="form-label">
									{t("Phone (optional)")}
								</label>
								<PhoneInput
									reset
									defaultCountry="US"
									value={formik.values.phone}
									onChange={value => {
										if(value) {
											formik.values.phone = value;
										} else {
											formik.values.phone = "";
										}
									}}
									className={
										(formik.values.phone &&
											!isValidPhoneNumber(formik.values.phone)
											? "is-invalid"
											: "") + " form-control"
									}
								/>
								{formik.values.phone &&
									!isValidPhoneNumber(formik.values.phone) ? (
									<div className="invalid-feedback-copy">
										{t("Invalid phone number.")}
									</div>
								) : (
									""
								)}
							</div>

							{isFree || !stripe || !selectedPrice ? (
								<></>
							) : (
								<>
									{(discountPrice === null ||
										discountPrice > 0) && (
											<>
												{paymentRequest ? (
													<PaymentRequestButtonElement
														onReady={() => updateSize()}
														className="pay-button"
														options={{
															paymentRequest,
															style: {
																paymentRequestButton: {
																	theme: "dark",
																	height: "45px"
																}
															}
														}}
													/>
												) : (
													<></>
												)}
												<div className="form-group mb-6">
													<div className="form-control pt-4">
														<CardElement
															options={{
																style: {
																	base: {
																		fontSize:
																			"14px",
																		fontFamily:
																			"Poppins, Helvetica, 'sans-serif'",
																		color:
																			"#181c32",
																		"::placeholder": {
																			color:
																				"#181c32"
																		}
																	},
																	invalid: {
																		color: "#f1416c"
																	}
																}
															}}
														/>
													</div>
													{cardFeedback && (
														<div className="invalid-feedback-copy">
															{cardFeedback}
														</div>
													)}
												</div>
											</>
										)}
									{product.hasCoupons && selectedPrice?.type != "free" && selectedPrice?.type != "pay-what-you-want" && (
										<div className="form-group mb-0">
											<label className="form-label">
												{t("Coupon Code (optional)")}
											</label>
											<div className="field-with-spinner">
												{couponLoading && (
													<div className="spinner spinner-sm" />
												)}
												<input
													type="text"
													className="form-control"
													onInput={couponOnInput}
													ref={couponInputRef}
												/>
											</div>
										</div>
									)}
								</>
							)}
						</div>
						<div className="modal-footer justify-content-start">
							<Price
								price={selectedPrice}
								customAmount={formik.values.amount}
								label
								discountPrice={discountPrice}
							/>

							<div className="text-right flex-grow-1">
								{isFree ? (
									<button
										type="submit"
										className={
											"btn btn-primary " +
											(loading ? "loading spinner" : "")
										}
									>
										{t("Get Access")}
									</button>
								) : (
									<button
										type="submit"
										disabled={!selectedPrice}
										className={
											"btn btn-primary " +
											(loading ? "loading spinner" : "")
										}
									>
										{t("Buy Now")}
									</button>
								)}
							</div>
						</div>
					</Form>
				)}
			</Formik>

			<ReCaptcha
				ref={recaptchaRef}
				sitekey={process.env.REACT_APP_RECAPTCHA}
				verifyCallback={recaptchaCallback}
			/>
		</>
	);
}

/**
 * Component `PaymentFormElements`.
 * Wrapper component that injects Stripe elements
 */
export function PaymentFormElements(props) {
	const elements = useElements();
	const stripe = useStripe();

	return <PaymentForm elements={elements} stripe={stripe} {...props} />;
}
