import React, { useState, useRef } from "react";
import { Switch } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import "./stripeTestModeSwitch.scss";
import api from "../../../../../../redux/api";
import actions from "../../../../../../redux/actions";

const Toggler = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      className="stripe-test-mode-switch-toggler"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
});

export function StripeTestModeSwitch() {
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [checked, setChecked] = useState(user.paymentTestMode || false);
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeoutRef = useRef(null);
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 500); 
  };

  const saveTestModeSetting = async (enabled) => {
    await api.user.setPaymentTestMode(enabled);
    await updateUser();
  }

  async function updateUser() {
    const res = await api.auth.getUserByToken(true);
    dispatch(actions.auth.fulfillUser(res.data));
  }

  return (
    <Dropdown
      drop="down"
      className="stripe-test-mode-switch"
      show={isOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Dropdown.Toggle
        variant="transparent"
        as={Toggler}
      >
        <label className="d-flex align-items-center mb-0 pl-4">
          <span className={`pr-4 flex-grow-1 ${checked ? 'text-danger' : 'text-muted'}`}>
            Test mode
          </span>

          <Switch
            checked={checked}
            onChange={(e) => {
              const value = e.target.checked;

              setChecked(value);
              saveTestModeSetting(value);
            }}
            className="mr-1 switch"
          />
        </label>
      </Dropdown.Toggle>
      <Dropdown.Menu
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          Test mode lets you simulate transactions and payments. In test mode, no real transactions will be processed. <a href="https://docs.stripe.com/test-mode" target="_blank">Learn more.</a>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

